import React from "react";
import { useEffect, useState, useId } from "react";

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
  Font,
} from "@react-pdf/renderer";
import Logo from "../../../../assets/img/Dwelly_FullLogo_Transparent.png";
import moment from "moment";
import FontVerdana from "../../../../assets/fonts/Verdana.ttf";
import FontVerdanaBold from "../../../../assets/fonts/Verdana Bold.ttf";
import FontVerdanaItalic from "../../../../assets/fonts/Verdana Italic.ttf";
import FontVerdanaBoldItalic from "../../../../assets/fonts/Verdana Bold Italic.ttf";
import { last } from "lodash";
Font.register({
  family: "Verdana",
  fonts: [
    { src: FontVerdana },
    { src: FontVerdanaBold, fontWeight: "bold" },
    { src: FontVerdanaItalic, fontStyle: "italic" },
    { src: FontVerdanaBoldItalic, fontWeight: "bold", fontStyle: "italic" },
  ],
});
// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    fontFamily: "Verdana",
    padding: 20,
  },
  text: {
    fontSize: 10,
  },
  section: {
    padding: 20,
    paddingLeft: 25,
    paddingRight: 25,
  },
});

// Create Document Component
const formatCurrency = (amount) => {
  return `$${amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
};
const Invoice = ({ deal }) => {
  const [mlsData, setMlsData] = useState(null);
  useEffect(() => {
    if (deal && !mlsData) {
      getMLSListingDetails(deal.MLS);
    }
  }, [deal]);
  async function getMLSListingDetails(MLS) {
    let toSend;
    let resp = await (await fetch(`/api_v2/properties/${MLS}/`)).json();
    if (!resp.board_2 && !resp.board_16 && !resp.board_17) {
      console.log("WRONG MLS");
    } else {
      if (resp.board_16) {
        toSend = resp.board_16;
        console.log(resp);
      } else if (resp.board_2) {
        toSend = resp.board_2;
      } else {
        toSend = resp.board_17;
      }
    }
    if (toSend) {
      setMlsData(toSend);
    }
    console.log({ toSend });
    return toSend;
  }
  const price = parseFloat(deal.sale_price);
  const rebate = 0.01;
  const hasFixedRebate = parseFloat(deal.dwelly_home_buyer_rebate_yes) > 0;
  const rebateAmount = hasFixedRebate
    ? parseFloat(deal.dwelly_home_buyer_rebate_yes)
    : salePrice * rebate;

  const commission =
    (parseFloat(deal.sale_price) * parseFloat(deal.total_commission_buyagent)) /
    100;
  const hst = (commission * 13) / 100;
  console.log({ commission, hst, mlsData, deal });
  return (
    mlsData && (
      <PDFViewer style={{ flexGrow: 1, width: "100%", height: "90vh" }}>
        <Document>
          <Page size={"LETTER"} style={styles.page}>
            <View style={styles.section}>
              <Image style={{ width: "30%" }} src={Logo} />
              <Text style={{ fontWeight: "bold", fontSize: 12 }}>
                Dwelly Realty Inc.
              </Text>
              <Text style={{ fontSize: 10 }}>
                405 The West Mall, Suit 910, {"\n"}Toronto, Ontario M9C 5J1
                {"\n"}647.797.1133/hello@dwelly.ca
              </Text>
            </View>

            <View style={{ ...styles.section, textAlign: "justify" }}>
              <Text
                style={{
                  ...styles.text,
                  fontWeight: "extrabold",
                  textAlign: "center",
                }}
              >
                The Dwelly Buyer Rebate Agreement
              </Text>
              <Text style={{ ...styles.text, paddingTop: 20 }}>
                The Purchaser's agent, Dwelly Realty Inc., agrees to pay the
                Purchaser a rebate equal to{" "}
                {hasFixedRebate
                  ? formatCurrency(rebateAmount) + " from"
                  : "50% of"}{" "}
                the received commission as stated on the MLS listing of
                purchased property, or other negotiated commission amount paid
                to Dwelly Realty Inc. The Purchaser will receive{" "}
                {hasFixedRebate
                  ? formatCurrency(rebateAmount) + " from"
                  : "50% of"}{" "}
                the commission paid by the seller to Dwelly Realty Inc.
              </Text>
              <Text style={{ ...styles.text, paddingTop: 15 }}>
                The Dwelly Buyers Rebate is subject to the following Disclosures
                and Requirements in accordance with the laws of the Province of
                Ontario:
              </Text>
              <View style={{ flexDirection: "row", paddingRight: 10 }}>
                <Text style={{ ...styles.text }}>{" · "}</Text>
                <Text style={{ ...styles.text }}>
                  This program does not apply if the purchaser is currently
                  working with a real estate agent, as it is not intended to be
                  a solicitation to participate and you are not eligible;
                </Text>
              </View>
              <View style={{ flexDirection: "row", paddingRight: 10 }}>
                <Text style={{ ...styles.text }}>{" · "}</Text>
                <Text style={{ ...styles.text }}>
                  Execution of this agreement does not place the purchaser under
                  any obligation to enter into an Agreement of Purchase and Sale
                  with the seller;
                </Text>
              </View>
              <View style={{ flexDirection: "row", paddingRight: 10 }}>
                <Text style={{ ...styles.text }}>{" · "}</Text>
                <Text style={{ ...styles.text }}>
                  The Purchaser is to notify the seller's agent that they are
                  represented by Dwelly Realty Inc and the applicable agent's
                  name and this information must be written on the Agreement of
                  Purchase and Sale;
                </Text>
              </View>
              <View style={{ flexDirection: "row", paddingRight: 10 }}>
                <Text style={{ ...styles.text }}>{" · "}</Text>
                <Text style={{ ...styles.text }}>
                  Dwelly Realty Inc. makes no representations regarding the tax
                  implications of this rebate and no subsequent taxes will be
                  withheld;
                </Text>
              </View>
              <View style={{ flexDirection: "row", paddingRight: 10 }}>
                <Text style={{ ...styles.text }}>{" · "}</Text>
                <Text style={{ ...styles.text }}>
                  The Purchaser assumes the responsibility of disclosing this
                  rebate to their lender; and,
                </Text>
              </View>
              <View style={{ flexDirection: "row", paddingRight: 10 }}>
                <Text style={{ ...styles.text }}>{" · "}</Text>
                <Text style={{ ...styles.text }}>
                  Assuming Dwelly Realty Inc. is the Purchaser's Agent and has
                  received the agreed upon commission of 2.50% from the seller,{" "}
                  {hasFixedRebate
                    ? formatCurrency(rebateAmount) + " will be the "
                    : "50% of the received commission totalling 1.25% will constitute a"}{" "}
                  rebate to the Purchaser.
                </Text>
              </View>
            </View>

            <View
              style={{ ...styles.section, paddingTop: 0, paddingBottom: 0 }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View style={{ width: "50%", flexDirection: "column" }}>
                  <Text style={{ ...styles.text, fontWeight: "bold" }}>
                    {deal.buyers.length > 1
                      ? "Buyers Informations"
                      : "Buyer Information"}
                  </Text>
                  {deal.buyers.map((buyer, index) => (
                    <>
                      <Text style={{ ...styles.text }}>Name: {buyer.name}</Text>
                      <Text style={{ ...styles.text }}>
                        Phone: {buyer.phone}
                      </Text>
                      <Text style={{ ...styles.text }}>
                        Email: {buyer.email}
                      </Text>
                    </>
                  ))}
                </View>
                <View style={{ width: "50%", flexDirection: "column" }}>
                  <Text style={{ ...styles.text, fontWeight: "bold" }}>
                    Property Information
                  </Text>
                  <Text style={{ ...styles.text }}>
                    Address: {deal.address}
                  </Text>
                  <Text style={{ ...styles.text }}>
                    Phone: {formatCurrency(price)}
                  </Text>
                  <Text style={{ ...styles.text }}>
                    Total Rebate: {formatCurrency(rebateAmount)}
                  </Text>
                </View>
              </View>
            </View>
            <View
              style={{ ...styles.section, paddingTop: 80, paddingBottom: 0 }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View
                  style={{
                    width: "50%",
                    flexDirection: "column",
                  }}
                >
                  <Text
                    style={{
                      ...styles.text,
                      fontWeight: "bold",
                      borderTop: 1,
                      width: "80%",
                    }}
                  >
                    Purchaser:
                  </Text>
                </View>
                <View
                  style={{
                    width: "50%",
                    flexDirection: "column",
                  }}
                >
                  <Text
                    style={{
                      ...styles.text,
                      fontWeight: "bold",
                      borderTop: 1,
                      width: "80%",
                    }}
                  >
                    Salesperson:
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.section}>
              <Text
                style={{
                  ...styles.text,
                  textAlign: "center",
                  marginTop: 40,
                }}
              >
                {"\n"}
                <Text style={{ fontWeight: "bold" }}>
                  Dwelly Realty Inc., Brokerage
                </Text>
                {"\n"}405 The West Mall, Suit 910, Toronto, Ontario M9C 5J1{" "}
                {"\n"}
                Tel: 647.797.1133 -- Email: deals@dwellyrealty.com
              </Text>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    )
  );
};
export default Invoice;
