import {
  Avatar,
  Badge,
  Button,
  Flex,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import moment from "moment";
import { useLocalStorage } from "../../useLocalStorage";
import { upperCase, upperFirst } from "lodash";
function TablesTableRow(props) {
  const [auth, setAuth] = useLocalStorage("auth_v2", null);
  const isSuperAdmin = auth?.roles?.superAdmin;
  const {
    MLS,
    logo,
    name,
    email,
    subdomain,
    domain,
    status,
    date,
    row,
    agentInfo,
    captions,
  } = props;
  console.log({ captions, row });
  const textColor = useColorModeValue("gray.700", "white");
  const bgStatus = useColorModeValue("gray.400", "#1a202c");
  const colorStatus = useColorModeValue("white", "gray.400");
  const nav = (
    <NavLink to={"/admin/deals/" + row._id}>
      <Button p="0px" bg="transparent" variant="no-hover">
        <Text fontSize="md" color="gray.400" fontWeight="bold" cursor="pointer">
          View Details
        </Text>
      </Button>
    </NavLink>
  );
  return (
    <Tr>
      {captions.map((caption, idx) => {
        if (caption !== "")
          return (
            <Td key={"td_" + idx} pl="0px">
              <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                <Flex direction="column">
                  <Text
                    fontSize="md"
                    color={textColor}
                    fontWeight="bold"
                    minWidth="100%"
                    textDecoration={"none"}
                  >
                    {row && row[caption] && typeof row[caption] === "string"
                      ? row[caption]
                      : row && row[caption] && row[caption].length > 0
                      ? Object.values(row[caption]).map((item, i) => (
                          <Badge
                            key={"badge_" + i}
                            style={{ textTransform: "lowercase" }}
                            ml="1"
                            fontSize="0.8em"
                            colorScheme="green"
                          >
                            {item}
                          </Badge>
                        ))
                      : null}
                  </Text>
                </Flex>
              </Flex>
            </Td>
          );
      })}

      <Td display={{ sm: "none", md: "table-cell" }}>{nav}</Td>
    </Tr>
  );
}

export default TablesTableRow;
