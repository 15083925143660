import React, { useState, useEffect } from "react";
import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Text,
  Heading,
  Button,
} from "@chakra-ui/react";
import { cond, filter, set, last, find } from "lodash";
import DynamicForm from "../../../../components/DynamicForm";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { useLocalStorage } from "useLocalStorage";
import DealCalculations from "./DealCalculations";
const CurrencyFormatter = (value) => {
  if (value < 0) {
    return (
      "(" +
      new Intl.NumberFormat("en-CA", {
        style: "currency",
        currency: "CAD",
        negativeFormat: "brackets",
      }).format(Math.abs(value)) +
      ")"
    );
  } else {
    return new Intl.NumberFormat("en-CA", {
      style: "currency",
      currency: "CAD",
      negativeFormat: "brackets",
    }).format(value);
  }
};
const Fields = [
  {
    type: "select",
    label: "Account",
    name: "account",
    placeholder: "Select An Account",
    required: true,
    options: [
      {
        label: "Real Estate Trust",
        value: "real_estate_trust",
      },
      {
        label: "Commission Trust",
        value: "commission_trust",
      },
      {
        label: "General",
        value: "general",
      },
    ],
  },
  { type: "text", label: "Details", name: "details" },
  {
    type: "date",
    label: "Date",
    name: "date",
    placeholder: "Select Date",
  },
  { type: "text", label: "Reference", name: "reference" },
  { type: "number", label: "Amount", name: "amount" },
  {
    type: "select",
    label: "Type",
    name: "type",
    placeholder: "Select An Type",
    required: true,
    options: [
      {
        label: "Debit",
        value: "debit",
      },
      {
        label: "Credit",
        value: "credit",
      },
    ],
  },
  {
    type: "select",
    label: "To",
    name: "to",
    placeholder: "Select...",
    conditions: [{ name: "type", value: "credit" }],
    options: [
      {
        label: "Commission Trust",
        value: "commission_trust",
      },
      {
        label: "Real Estate Trust",
        value: "real_estate_trust",
      },
      {
        label: "Dwelly General",
        value: "general",
      },
      {
        label: "Buyer",
        value: "buyer",
      },
      {
        label: "Buyer Rebate",
        value: "buyer_rebate",
      },
      {
        label: "Seller",
        value: "seller",
      },
      {
        label: "Owner",
        value: "owner",
      },
      {
        label: "Seller Rebate",
        value: "seller_rebate",
      },
      {
        label: "Seller Lawyer",
        value: "seller_lawyer",
      },
      {
        label: "Dwelly Agent",
        value: "agent_dwelly",
      },
      {
        label: "Ohter Agent",
        value: "agent_other",
      },
      {
        label: "E-Transfer",
        value: "etransfer",
      },
    ],
  },
];
const DealTransactions = ({
  dealId,
  deal,
  allAgents,
  allTransactions,
  viewOnly,
}) => {
  const [transactions, setTransactions] = useState(null);
  const [dirtyTxns, setDirtyTxns] = useState([]);
  const [RSTrust, setRSTrust] = useState([]);
  const [CTrust, setCTrust] = useState([]);
  const [General, setGeneral] = useState([]);
  const [txnId, setTxnId] = useState(null);
  const [txnData, setTxnData] = useState(null);
  const [auth, setAuth] = useLocalStorage("auth_v2", null);
  const {
    agents,
    primaryAgent,
    dwellyRebate,
    otherAgents,
    salePrice,
    buyerCommissionPercentage,
    sellerCommissionPercentage,
    totalCommissionPercentage,
    buyerCommission,
    sellerCommission,
    primaryAgentCommission,
    totalCommission,
    totalCommissionHST,
    otherAgentCommissions,
    primaryAgentEmail,
    primaryAgentInfo,
    coopRebate,
    sellerRebate,
    agentComissions,
    totalAgentCommissions,
    officeCommission,
    personaSaleFee,
    HST,
    isco_op,
    rebate,
    hasRebate,
    Comission,
    Expenses,
    BaseOfficeCommission,
    AgentsLines,
    AgentTotalsLine,
    NetOfficeLine,
  } = DealCalculations(deal, allAgents);
  const handleFormSubmit = async (formState, auth, type) => {
    console.log("debug", { formState, dirtyTxns, transactions });
    if (txnId === "_id") {
      setTransactions([
        ...transactions,
        { ...formState, dirty: true, _id: uuidv4() },
      ]);
    } else {
      setTransactions([
        ...transactions.map((txn) => {
          if (txn._id === formState._id) {
            return { ...formState, dirty: true };
          }
          return txn;
        }),
      ]);
    }
    setTxnId(null);
  };
  const saveTransactions = () => {
    fetch(`/api/deals/${dealId}/transactions`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        dwelly_token: auth.token,
      },
      body: JSON.stringify(dirtyTxns),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setTransactions(
          transactions.map((txn) => {
            delete txn.dirty;
            return txn;
          })
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const txn = {
    _id: "_id",
    account: "",
    amount: 0,
    details: "",
    date: undefined,
    reference: "",
    type: "debit",
    to: "commission_trust",
    dirty: true,
  };
  const titles = {
    real_estate_trust: "Real Estate Trust",
    commission_trust: "Commission Trust",
    general: "General",
  };
  useEffect(() => {
    fetch(`/api/deals/${dealId}/transactions`, {
      headers: {
        dwelly_token: auth.token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setTransactions(data);
      })
      .catch((e) => {
        console.log(e);
        setTransactions([]);
      });
  }, [dealId, auth]);
  useEffect(() => {
    if (txnId) {
      if (txnId === "_id") {
        setTxnData({ ...txn });
      } else {
        const txn = find(transactions, { _id: txnId });
        console.log("debug", { txn });
        setTxnData(txn);
      }
    } else {
      setTxnData(null);
    }
  }, [txnId]);
  const generate = () => {
    let values = [];
    if (!isco_op) {
      if (deal.deal_type === "Lease") {
        values.push({
          account: "real_estate_trust",
          details: "Renter Deposit 1st and 2nd Month",
          amount: parseFloat(deal.sale_price) * 2,
          type: "debit",
        });
        values.push({
          account: "real_estate_trust",
          details: "One month rent to Owner",
          amount:
            parseFloat(deal.sale_price) * 2 -
            Math.round(last(Comission) * 100) / 100,
          type: "credit",
          to: "owner",
        });
      } else {
        values.push({
          account: "real_estate_trust",
          details: "Buyer Deposit",
          amount: 0,
          type: "debit",
        });
      }

      values.push({
        account: "real_estate_trust",
        details: "Commissions",
        amount: Math.round(last(Comission) * 100) / 100,
        type: "credit",
        to: "commission_trust",
      });
      values.push({
        account: "commission_trust",
        details: "Commissions",
        amount: Math.round(last(Comission) * 100) / 100,
        type: "debit",
      });
    }
    if (isco_op) {
      if (deal.deal_type === "Lease") {
        values.push({
          account: "commission_trust",
          details:
            "Invoice Payment from " +
            (deal.other_brokerages && deal.other_brokerages.length > 0)
              ? deal.other_brokerages[0].name
              : "Other Brokerage",
          reference:
            moment(deal.closing_date).format("YYYYMM-") +
            last(deal._id.split("-")),
          amount: last(Comission),
          type: "debit",
        });
      } else {
        values.push({
          account: "commission_trust",
          details:
            "Invoice Payment from " +
            (deal.other_brokerages && deal.other_brokerages.length > 0)
              ? deal.other_brokerages[0].name
              : "Other Brokerage",
          reference:
            moment(deal.closing_date).format("YYYYMM-") +
            last(deal._id.split("-")),
          amount: last(Comission),
          type: "debit",
        });
      }
    } else {
      values.push({
        account: "commission_trust",
        details:
          "Payment to  " +
          (deal.other_brokerages && deal.other_brokerages.length > 0
            ? deal.other_brokerages[0].name
            : "Other Brokerage"),
        amount: Math.round(last(Expenses[0]) * 100) / 100,
        type: "credit",
        to: "agent_other",
      });
    }
    if (hasRebate) {
      values.push({
        account: "commission_trust",
        details: (isco_op ? "Buyer" : "Seller") + " Rebate",
        amount: dwellyRebate,
        type: "credit",
        to: "buyer_rebate",
      });
    }
    AgentsLines.map((line) => {
      values.push({
        account: "commission_trust",
        details: line[0],
        amount: Math.round(last(line) * 100) / 100,
        type: "credit",
        to: "agent_dwelly",
        reference: line[0],
      });
    });
    values.push({
      account: "commission_trust",
      details: "Office Commission",
      amount: NetOfficeLine[NetOfficeLine.length - 3],
      type: "credit",
      to: "general",
    });
    values.push({
      account: "commission_trust",
      details: "Office Commission  HST",
      amount: NetOfficeLine[NetOfficeLine.length - 2],
      type: "credit",
      to: "general",
    });
    values.push({
      account: "general",
      details: "Office Commission",
      amount: NetOfficeLine[NetOfficeLine.length - 3],
      type: "debit",
    });
    values.push({
      account: "general",
      details: "Office Commission  HST",
      amount: NetOfficeLine[NetOfficeLine.length - 2],
      type: "debit",
    });
    return values.map((v) => {
      v.dirty = true;
      v._id = uuidv4();
      return v;
    });
  };
  useEffect(() => {
    if (dealId && transactions && transactions.length === 0) {
      console.log("debug_txn", "generate");
      setTransactions(generate());
    }
    if (dealId && transactions && transactions.length > 0) {
      setRSTrust(filter([...transactions], { account: "real_estate_trust" }));
      setCTrust(filter([...transactions], { account: "commission_trust" }));
      setGeneral(filter([...transactions], { account: "general" }));
      setDirtyTxns(filter([...transactions], { dirty: true }));
    }
    console.log("debug_txn", { transactions });
  }, [transactions]);
  useEffect(() => {
    console.log("debug_txn", { dirtyTxns });
  }, [dirtyTxns]);
  useEffect(() => {}, [RSTrust, CTrust, General]);
  const getTable = (title, data, set) => {
    const toText = find(Fields, { name: "to" }).options;
    return (
      <>
        <Heading as={"h2"} size="xs">
          {titles[title]}
        </Heading>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>Txn Date</Th>
                <Th>Details</Th>
                <Th>Reference</Th>
                <Th>To</Th>
                <Th>Amount</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data &&
                data.map((transaction) => {
                  const toLabel = find(toText, { value: transaction.to });
                  const amount = CurrencyFormatter(transaction.amount);
                  return (
                    <Tr>
                      <Td>
                        {transaction.date
                          ? moment(transaction.date).format("ll")
                          : "-"}
                      </Td>
                      <Td>{transaction.details}</Td>
                      <Td>{transaction.reference}</Td>
                      <Td>{toLabel && toLabel.label}</Td>
                      <Td>
                        {transaction.type === "debit"
                          ? amount
                          : "(" + amount + ")"}
                      </Td>
                      <Td>
                        {!viewOnly && (
                          <Button
                            size="sm"
                            onClick={() => {
                              setTxnId(transaction._id);
                            }}
                          >
                            Edit
                          </Button>
                        )}
                      </Td>
                    </Tr>
                  );
                })}
              <Tr>
                <Td></Td>
                <Td></Td>
                <Td></Td>
                <Td fontWeight="bold" textAlign={"right"}>
                  Balance:
                </Td>
                <Td fontWeight="bold">
                  {data && data.length > 0
                    ? CurrencyFormatter(
                        data.reduce(
                          (acc, t) =>
                            t.date
                              ? t.type === "debit"
                                ? acc + t.amount
                                : acc - t.amount
                              : acc,
                          0
                        )
                      )
                    : CurrencyFormatter(0)}
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      </>
    );
  };
  return (
    <div>
      <h2>
        Transactions for {deal.address}{" "}
        {!viewOnly && (
          <Button
            marginLeft={10}
            size="xs"
            onClick={() => {
              setTxnId("_id");
            }}
          >
            Add
          </Button>
        )}
      </h2>
      {txnData && (
        <DynamicForm
          key={txnData._id}
          fixWidth={true}
          formData={Fields}
          onFormSubmit={handleFormSubmit}
          inline={true}
          data={txnData}
          viewOnly={false}
          saving={false}
          generate={null}
          uploading={false}
          isTable={true}
          btnText={txnData._id === "_id" ? "Add" : "Update"}
        />
      )}
      {getTable("real_estate_trust", RSTrust, setRSTrust)}
      {getTable("commission_trust", CTrust, setCTrust)}
      {getTable("general", General, setGeneral)}
      {!viewOnly && dirtyTxns.length > 0 && (
        <Button onClick={saveTransactions}>Save</Button>
      )}
    </div>
  );
};
export default DealTransactions;
