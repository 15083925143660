import React from "react";
import { useEffect, useState, useId } from "react";

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
  Font,
} from "@react-pdf/renderer";
import Logo from "../../../../assets/img/Dwelly_FullLogo_Transparent.png";
import moment from "moment";
import FontVerdana from "../../../../assets/fonts/Verdana.ttf";
import FontVerdanaBold from "../../../../assets/fonts/Verdana Bold.ttf";
import FontVerdanaItalic from "../../../../assets/fonts/Verdana Italic.ttf";
import FontVerdanaBoldItalic from "../../../../assets/fonts/Verdana Bold Italic.ttf";
import { last } from "lodash";
Font.register({
  family: "Verdana",
  fonts: [
    { src: FontVerdana },
    { src: FontVerdanaBold, fontWeight: "bold" },
    { src: FontVerdanaItalic, fontStyle: "italic" },
    { src: FontVerdanaBoldItalic, fontWeight: "bold", fontStyle: "italic" },
  ],
});
// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    fontFamily: "Verdana",
    padding: 20,
  },
  text: {
    fontSize: 10,
  },
  body: {
    fontSize: 12,
  },
  section: {
    margin: 10,
    padding: 10,
    paddingLeft: 25,
    paddingRight: 25,
  },
});

// Create Document Component
const formatCurrency = (amount) => {
  return `$${amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
};
const Invoice = ({ deal }) => {
  const [mlsData, setMlsData] = useState(null);
  useEffect(() => {
    if (deal && !mlsData) {
      getMLSListingDetails(deal.MLS);
    }
  }, [deal]);
  async function getMLSListingDetails(MLS) {
    let toSend;
    let resp = await (await fetch(`/api_v2/properties/${MLS}/`)).json();
    if (!resp.board_2 && !resp.board_16 && !resp.board_17) {
      console.log("WRONG MLS");
    } else {
      if (resp.board_16) {
        toSend = resp.board_16;
        console.log(resp);
      } else if (resp.board_2) {
        toSend = resp.board_2;
      } else {
        toSend = resp.board_17;
      }
    }
    if (toSend) {
      setMlsData(toSend);
    }
    console.log({ toSend });
    return toSend;
  }
  const price = parseFloat(deal.sale_price);
  const commission =
    (parseFloat(deal.sale_price) * parseFloat(deal.total_commission_buyagent)) /
    100;
  const hst = (commission * 13) / 100;
  console.log({ commission, hst, mlsData, deal });
  const document = (
    <Document>
      <Page size={"LETTER"} style={styles.page}>
        <View style={styles.section}>
          <Image style={{ width: "30%" }} src={Logo} />
          <Text style={styles.text}>{"Dwelly Realy Inc., Brokerage"}</Text>
          <Text style={styles.text}>
            {"405 The West Mall, Suit 910, Toronto, Ontario M9C 5J1"}
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={{ ...styles.text }}>
            {moment(deal.buyer_deposit_date).format("LL")}
          </Text>
          <Text
            style={{
              ...styles.text,
              fontWeight: "extrabold",
              fontSize: 20,
            }}
          >
            Receipt
          </Text>
        </View>
        <View style={{ ...styles.section, width: "100%" }}>
          <View
            style={{
              flexDirection: "row",
              height: 20,
            }}
          >
            <Text style={{ ...styles.body }}>Recieved From:</Text>
            <Text style={{ ...styles.body }}>{deal.buyer_deposit_name}</Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              height: 20,
            }}
          >
            <Text style={{ ...styles.body }}>Funds Recieved: </Text>
            <Text style={{ ...styles.body }}>
              {" "}
              {formatCurrency(parseFloat(deal.buyer_deposit))}
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              height: 20,
            }}
          >
            <Text style={{ ...styles.body }}>Trust Deposit On:</Text>
            <Text style={{ ...styles.body }}>{deal.address}</Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              height: 20,
            }}
          >
            <Text style={{ ...styles.body }}>Fund Currency: </Text>
            <Text style={{ ...styles.body }}>CAD</Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              height: 20,
            }}
          >
            <Text style={{ ...styles.body }}>Date Recieved:</Text>
            <Text style={{ ...styles.body }}>
              {moment(deal.buyer_deposit_date).format("LL")}
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              height: 20,
            }}
          >
            <Text style={{ ...styles.body }}>Payment Type: </Text>
            <Text style={{ ...styles.body }}>Deposit</Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              height: 20,
            }}
          >
            <Text style={{ ...styles.body }}>Payment Type: </Text>
            <Text style={{ ...styles.body }}>Deposit</Text>
          </View>
        </View>

        <View style={styles.section}>
          <Text
            style={{
              ...styles.text,
              marginTop: 90,
            }}
          >
            Yours truly
          </Text>
          <Text
            style={{
              ...styles.text,
            }}
          >
            {"\n"}Dwelly Admin
          </Text>
          <Text
            style={{
              ...styles.text,
              fontStyle: "italic",
              marginTop: 100,
            }}
          >
            <Text style={{ textDecoration: "underline", fontWeight: "bold" }}>
              COMPANY POLICY FOR DEPOSITS CHEQUES:
            </Text>{" "}
            In the event that the conditions in your Agreement of Purchase and
            Sale are not satisfied, and a request for a Return of the deposit by
            way of Mutual Release is required - please not for{" "}
            <Text style={{ textDecoration: "underline", fontWeight: "bold" }}>
              Uncertified/Personal cheques
            </Text>{" "}
            the deposit shall NOT be returned untill{" "}
            <Text style={{ textDecoration: "underline", fontWeight: "bold" }}>
              15 Business days after
            </Text>
            it has been deposited and cleared by our bank. For{" "}
            <Text style={{ textDecoration: "underline", fontWeight: "bold" }}>
              Bank Drafts or Certified Cheques
            </Text>{" "}
            the deposit will{" "}
            <Text style={{ textDecoration: "underline", fontWeight: "bold" }}>
              NOT
            </Text>{" "}
            be returned untill{" "}
            <Text style={{ textDecoration: "underline", fontWeight: "bold" }}>
              5 business days
            </Text>{" "}
            after it has been deposited and cleared by our bank.
          </Text>

          <Text
            style={{
              ...styles.text,
              textAlign: "center",
              marginTop: 20,
            }}
          >
            {"\n"}
            <Text style={{ fontWeight: "bold" }}>
              Dwelly Realty Inc., Brokerage
            </Text>
            {"\n"}405 The West Mall, Suit 910, Toronto, Ontario M9C 5J1 {"\n"}
            Tel: 647.797.1133 -- Email: deals@dwellyrealty.com
          </Text>
        </View>
      </Page>
    </Document>
  );
  return (
    mlsData && (
      <PDFViewer style={{ flexGrow: 1, width: "100%", height: "90vh" }}>
        {document}
      </PDFViewer>
    )
  );
};
export default Invoice;
