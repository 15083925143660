// Chakra imports
import { Flex } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Deals from "./components/Deals";
import Projects from "./components/Projects";
import { tablesTableData, dashboardTableData } from "variables/general";
import { useLocalStorage } from "../../../useLocalStorage";
import routes from "../../../routes";
import moment from "moment";
import { upperFirst, sortBy, orderBy } from "lodash";
import { useHistory, useParams } from "react-router-dom";
function Tables({ location }) {
  console.log({ location });
  const { id } = useParams();

  const path = location.pathname.split("/");
  const type = path[path.length - 1];
  const [auth, setAuth] = useLocalStorage("auth_v2", null);
  const [leads, setLeads] = useState(null);
  const [status, setStatus] = useState(true);
  const [allAgents, setAllAgents] = useState([]);
  const [messages, setMessages] = useState([]);
  const [deals, setDeals] = useState([]);
  const [deal, setDeal] = useState(null);
  console.log({ deal });
  const [agentFilter, setAgentFilter] = useState(null);
  const getLeads = async () => {};
  console.log({ id });
  const reSet = () => {
    setDeal(null);
  };
  const reLoad = async (id) => {
    if (auth) {
      console.log("get agents");
      fetch("/api/agents", { headers: { dwelly_token: auth.token } }).then(
        async (messages) => {
          setAllAgents((await messages.json()).agents);
        }
      );
      if (id) {
        console.log("get deal");
        fetch("/api/deals/" + id, {
          headers: { dwelly_token: auth.token },
        }).then(async (messages) => {
          const deal = await messages.json();
          console.log("test 1", { deal });
          if (deal && !deal.deal_id && deals && deals.length > 0) {
            const maxId = deals.reduce(
              (max, item) =>
                parseInt(item.deal_id) > max ? parseInt(item.deal_id) : max,
              0
            );
            deal.deal_id = maxId + 1;
          }
          setDeal(deal);
        });
      } else {
        console.log("get deals");
        fetch("/api/deals", { headers: { dwelly_token: auth.token } }).then(
          async (messages) => {
            setDeals(await messages.json());
          }
        );
      }
    }
  };
  useEffect(() => {
    if (auth && id) reLoad(id);
    else if (!id) setDeal(null);
  }, [auth && id]);
  useEffect(() => {
    if (auth) reLoad();
  }, [auth]);
  console.log({ deals, allAgents, id, deal });
  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px", sm: "50px" }}>
      {auth && deals && allAgents && ((id && deal) || !id) ? (
        <Deals
          allAgents={allAgents}
          dealId={id}
          deal={deal}
          reLoad={reLoad}
          reSet={reSet}
          status={status}
          setStatus={setStatus}
          title={"Deals"}
          captions={["MLS", "deal_id", "address", "deal_type", "agents", ""]}
          data={deals}
          auth={auth}
        />
      ) : (
        <>waiting</>
      )}
    </Flex>
  );
}

export default Tables;
