import { cond, multiply } from "lodash";

// // collection: form_templates
export default FormData = {
  _id: "dwelly_deal_submission",
  form: [
    {
      type: "checkbox",
      label: "Super Admin",
      name: "superAdmin",
      placeholder: "Select An Option",
    },
    {
      type: "select",
      label: "Status",
      name: "status",
      placeholder: "Select A Status",
      multiply: false,
      required: true,
      options: [
        { label: "Active", value: "active" },
        { label: "In Active", value: "inactive" },
      ],
    },
    { type: "email", label: "Email", name: "_id", required: true },
    { type: "text", label: "Name", name: "name", required: true },

    {
      type: "tel",
      label: "Phone Number",
      name: "phone",
      required: true,
    },
    {
      type: "text",
      label: "Site",
      name: "site",
    },
    { type: "text", label: "HST#", name: "hst" },
    {
      type: "text",
      label: "Personal Deal Fee (default $2000)",
      name: "persona_deal",
      placeholder: "$",
    },
  ],
};
