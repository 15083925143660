import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Input as TextField,
  Button,
  Checkbox,
  CheckboxGroup,
  Stack,
  FormControl,
  FormLabel as FormControlLabel,
  FormLabel as InputLabel,
  Box,
  FormErrorMessage,
  useFileUpload,
  FileUploadHiddenInput,
  FileUploadRootProvider,
  Tbody,
  Tr,
  Td,
  Th,
  Thead,
  Table,
  TableCaption,
} from "@chakra-ui/react";
import Select from "./select";
import Upload from "./upload";
import { SingleDatepicker } from "chakra-dayzed-datepicker";
import _, { set } from "lodash";
import { useLocalStorage } from "useLocalStorage";
import { isEqual } from "lodash";
// } from "@mui/material";
// import MDButton from "components/MDButton";
// import MDDropzone from "components/MDDropzone";
// import MDAlert from "components/MDAlert";
// import { DatePicker, TimePicker, TimePickerToolbar } from "@mui/x-date-pickers";

/**
 * A dynamic form component that renders form fields based on the provided formData.
 *
 * @component
 * @param {Object[]} formData - An array of form field objects.
 * @param {string} formData[].type - The type of the form field (e.g., "text", "checkbox", "select").
 * @param {string} formData[].name - The name of the form field.
 * @param {string} formData[].label - The label for the form field.
 * @param {boolean} [formData[].required] - Indicates if the form field is required.
 * @param {Object[]} [formData[].options] - An array of options for select fields.
 * @param {string} formData[].options[].value - The value of the option.
 * @param {string} formData[].options[].label - The label for the option.
 * @param {Function} onFormSubmit - A callback function to handle form submission.
 */

const DynamicForm = ({
  formData,
  onFormSubmit,
  btnText,
  viewOnly,
  data,
  showCancel,
  uploading,
  saving,
  fixWidth,
  generate,
  isTable,
}) => {
  console.log({ formData, data });
  const [formState, setFormState] = useState(data ? data : {});
  const [groupManager, setGroupManager] = useState({});
  const [formEnabled, setFormEnabled] = useState(false);
  const [invalidForm, setInvalidForm] = useState({});
  const [auth, setAuth] = useLocalStorage("auth_v2", null);
  const isSuperAdmin = auth?.roles?.superAdmin;

  useEffect(() => {
    console.log("formState", formState);
  }, [formState]);
  function formatPhoneNumber(phoneNumber) {
    const cleanNum = phoneNumber.toString().replace(/\D/g, "");
    const match = cleanNum.match(/^(\d{3})(\d{0,3})(\d{0,4})$/);
    if (match) {
      return (
        (match[2] ? "(" : "") +
        match[1] +
        (match[2] ? ") " : "") +
        (match[2] ? match[2] + (match[3] ? "-" : "") : "") +
        match[3]
      );
    }
    return cleanNum;
  }

  function validateEmail(str) {
    let emailValidateRegex = /^\S+@\S+\.\S+$/;
    return str.match(emailValidateRegex);
  }

  function isConditionRendered(field) {
    if (field.superAdmin && !isSuperAdmin) {
      return false;
    } else if (field.conditions) {
      for (let condition of field.conditions) {
        if (
          formState[condition.name] === condition.value ||
          (Array.isArray(formState[condition.name]) &&
            formState[condition.name].includes(condition.value)) ||
          (formState[condition.name] && !condition.value)
        ) {
          return true;
        }
      }
    }
    return false;
  }

  const verifyFields = () => {
    let tmpForm = {};
    let isValid = true;
    for (const elem of formData) {
      //dont validate if the "other"/condition field is not displayed
      if (elem.conditions && !isConditionRendered(elem)) {
        continue;
      }
      if (elem.required === true && formState[elem.name] !== undefined) {
        if (
          (elem.type === "text" ||
            elem.type === "number" ||
            elem.type === "date" ||
            elem.type === "select") &&
          (formState[elem.name] + "").length <= 0
        ) {
          tmpForm = {
            ...tmpForm,
            [elem.name]: true,
          };
        } else if (elem.type === "tel") {
          if (formState[elem.name]?.length !== 14) {
            tmpForm = { ...tmpForm, [elem.name]: true };
          }
        } else if (elem.type === "checkboxGroup") {
          if (!(formState[elem.name] && formState[elem.name].length > 0)) {
            tmpForm = { ...tmpForm, [elem.name]: true };
          }
        } else if (elem.type === "email") {
          if (!formState[elem.name] || !validateEmail(formState[elem.name])) {
            tmpForm = { ...tmpForm, [elem.name]: true };
          }
        }
      } else if (elem.required) {
        console.log("debug", { elem });
        isValid = false;
      }
    }
    console.log("debug", { isValid, tmpForm });
    setInvalidForm(tmpForm);
    return isValid && Object.keys(tmpForm).length === 0;
  };

  const handleChange = (e) => {
    console.log("value", e);
    let { name, value, checked, type } = e.target;
    console.log({ name, value, checked, type, e: e.target });
    if (name.indexOf("[") > -1) {
      const pName = name.split("[")[0];
      const index = parseInt(name.split("[")[1].split("]")[0]);
      const newName = name.split(".")[1];
      let copy = [...formState[pName]];
      copy[index] = { ...copy[index], [newName]: value };
      setFormState({ ...formState, [pName]: copy });
    } else if (name.indexOf(".") > -1) {
      const pName = name.split(".")[0];
      const newName = name.split(".")[1];
      let copy = { ...formState[pName] };
      copy[newName] = value;
      setFormState({ ...formState, [pName]: copy });
    } else if (type === "checkbox") {
      let checkedArr = formState[name] ?? [];
      if (checked) {
        checkedArr.push(value);
      } else {
        if (checkedArr.indexOf(value) > -1) {
          checkedArr.splice(checkedArr.indexOf(value), 1);
        }
      }
      setFormState({ ...formState, [name]: checkedArr });
    } else if (type === "check") {
      setFormState({ ...formState, [name]: checked });
    } else if (type === "tel") {
      setFormState({ ...formState, [name]: formatPhoneNumber(value) });
    } else {
      setFormState({ ...formState, [name]: value });
    }
  };
  const handleChangeCheckbox = (e) => {
    console.log("value", e);
    let { name, value, checked, type } = e.target;
    console.log({ name, value, checked, type, e: e.target });
    setFormState({ ...formState, [name]: checked });
  };

  useEffect(() => {
    setFormEnabled(verifyFields());
  }, [formState]);
  useEffect(() => {
    setFormState({ ...formState, ...data });
  }, [data]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onFormSubmit(formState, auth);
  };
  const getGroupItemValue = (name, dv, isDate) => {
    let rValue;
    if (name.indexOf("[") > -1) {
      const pName = name.split("[")[0];
      const index = parseInt(name.split("[")[1].split("]")[0]);
      const newName = name.split(".")[1];
      rValue =
        formState[pName] && formState[pName][index][newName]
          ? isDate
            ? new Date(formState[pName][index][newName])
            : formState[pName][index][newName]
          : isDate
          ? undefined
          : dv
          ? dv
          : "";
      isDate && console.log({ name, dv, isDate, rValue });
    } else if (name.indexOf(".") > -1) {
      const pName = name.split(".")[0];
      const newName = name.split(".")[1];
      rValue =
        formState[pName] && formState[pName][newName]
          ? formState[pName][newName]
          : "";
    } else {
      rValue = formState[name]
        ? isDate
          ? new Date(formState[name])
          : formState[name]
        : isDate
        ? undefined
        : "";
    }
    return rValue;
  };
  const renderFormField = (field) => {
    switch (field.type) {
      case "group":
        if (!formState[field.name]) {
          setFormState({ ...formState, [field.name]: [{}] });
        }

        return (
          <Box
            style={{
              borderStyle: "solid",
              borderRadius: 15,
              borderColor: "lightgray",
              borderWidth: 1,
              padding: 15,
              width: "100%",
            }}
          >
            {formState[field.name] &&
              formState[field.name].map((o, i) =>
                field.fields.map((inField, index) =>
                  renderFormField({
                    ...inField,
                    type: inField.type,
                    name: field.name + "[" + i + "]" + "." + inField.name,
                    label: _.startCase(
                      field.placeholder +
                        "[" +
                        (formState[field.name].length > 1 ? i + 1 : "") +
                        "]" +
                        "." +
                        inField.name
                    ),
                  })
                )
              )}
            {field.name === "transactions" && (
              <Button
                onClick={() => {
                  if (generate) {
                    const trxn = generate("transactions");
                    //setFormState({...formState, transactions: [...formState.transactions, trxn]})
                    setFormState({ ...formState, transactions: trxn });
                  }
                }}
              >
                Generate {field.placeholder}
              </Button>
            )}
            <Button
              onClick={() => {
                setFormState({
                  ...formState,
                  [field.name]: [...formState[field.name], {}],
                });
              }}
            >
              + {field.placeholder}
            </Button>
            {formState[field.name] && formState[field.name].length > 1 && (
              <Button
                onClick={() => {
                  let tmp = [...formState[field.name]];
                  tmp.splice(-1, 1);
                  setFormState({
                    ...formState,
                    [field.name]: tmp,
                  });
                }}
              >
                - {field.placeholder}
              </Button>
            )}
          </Box>
        );
      case "text":
      case "number":
      case "tel":
      case "email":
        if (field.name === "sale_price") console.log("ff", { field });
        return (
          <FormControl
            isRequired={field.required}
            name={field.name}
            isInvalid={invalidForm[field.name]}
          >
            <FormControlLabel>
              {field.label || _.startCase(field.name)}
            </FormControlLabel>
            <TextField
              readOnly={viewOnly}
              placeholder={
                field.placeholder ? field.placeholder : _.startCase(field.label)
              }
              name={field.name}
              type={field.type}
              maxlength={field.type === "tel" ? 14 : 100}
              width={"100%"}
              value={getGroupItemValue(field.name, field.default)}
              onChange={handleChange}
            />
            <FormErrorMessage>Please enter a correct value </FormErrorMessage>
          </FormControl>
        );
      case "checkbox":
        if (field.name === "sale_price") console.log("ff", { field });
        return (
          <FormControl
            isRequired={field.required}
            name={field.name}
            isInvalid={invalidForm[field.name]}
          >
            <Checkbox
              readOnly={viewOnly}
              size="md"
              isChecked={
                formState[field.name]
                  ? formState[field.name]
                  : field.default
                  ? field.default
                  : false
              }
              name={field.name}
              onChange={handleChangeCheckbox}
            >
              {" "}
              {field.label || _.startCase(field.name)}{" "}
            </Checkbox>
          </FormControl>
        );

      case "checkboxGroup":
        return (
          <FormControl
            isRequired={field.isRequired}
            isInvalid={invalidForm[field.name]}
          >
            <CheckboxGroup colorScheme="red" value={formState[field.name]}>
              <FormControlLabel>{_.startCase(field.name)}</FormControlLabel>
              <Stack spacing={1} direction="column">
                {field.options.map((option) => (
                  <Checkbox
                    readOnly={viewOnly || isSuperAdmin !== field.superAdmin}
                    size="md"
                    type="checkbox"
                    value={option}
                    name={field.name}
                    onChange={handleChange}
                  >
                    {" "}
                    {option}{" "}
                  </Checkbox>
                ))}
              </Stack>
              <FormErrorMessage>Please enter a valid option </FormErrorMessage>
            </CheckboxGroup>
          </FormControl>
        );

      case "select":
        return (
          <FormControl
            isRequired={field.required}
            isInvalid={invalidForm[field.name]}
          >
            <FormControlLabel>
              {" "}
              {_.startCase(field.label) || _.startCase(field.name)}{" "}
            </FormControlLabel>
            <Select
              field={field}
              disabled={viewOnly}
              label={field.label}
              required={field.required}
              name={field.name}
              placeholder={field.placeholder}
              value={getGroupItemValue(field.name, "")}
              onChange={handleChange}
              multiple={field.multiple}
              options={field.options}
              formState={formState}
            ></Select>
          </FormControl>
        );

      case "date":
        return (
          <>
            <FormControl
              isRequired={field.required}
              isInvalid={invalidForm[field.name]}
            >
              <FormControlLabel>
                {" "}
                {_.startCase(field.label) || _.startCase(field.name)}{" "}
                <Button
                  size="xs"
                  onClick={() => {
                    const { name } = field;
                    if (name.indexOf("[") > -1) {
                      const pName = name.split("[")[0];
                      const index = parseInt(name.split("[")[1].split("]")[0]);
                      const newName = name.split(".")[1];
                      let copy = [...formState[pName]];
                      copy[index] = {
                        ...copy[index],
                        [newName]: "",
                      };
                      setFormState({ ...formState, [pName]: copy });
                    } else {
                      setFormState({
                        ...formState,
                        [field.name]: "",
                      });
                    }
                  }}
                >
                  reset
                </Button>{" "}
              </FormControlLabel>
              <SingleDatepicker
                disabled={viewOnly}
                name="date-input"
                date={getGroupItemValue(field.name, undefined, true)}
                onDateChange={(date) => {
                  const { name } = field;
                  if (name.indexOf("[") > -1) {
                    const pName = name.split("[")[0];
                    const index = parseInt(name.split("[")[1].split("]")[0]);
                    const newName = name.split(".")[1];
                    let copy = [...formState[pName]];
                    copy[index] = {
                      ...copy[index],
                      [newName]: date.toISOString(),
                    };
                    setFormState({ ...formState, [pName]: copy });
                  } else {
                    setFormState({
                      ...formState,
                      [field.name]: date.toISOString(),
                    });
                  }
                }}
              />
            </FormControl>
          </>
        );
      case "upload":
        return (
          <Upload
            _id={formState._id}
            value={formState[field.name] ?? []}
            name={field.name}
            field={field}
            data={data[field.name] ?? []}
            onChange={handleChange}
            auth={auth}
          />
        );
      default:
        return null;
    }
  };

  return isTable ? (
    <Tr overflow={"visible"}>
      {formData.map((field, index) => {
        const isCondRendered = isConditionRendered(field);

        //if 'other' field exists and not rendered, then delete its value in the state to prevent its dependencies to also render
        if (field.conditions && !isCondRendered) {
          delete formState[field.name];
        } else if (!field.conditions || isCondRendered) {
          return (
            <Td
              overflow={"visible"}
              key={index}
              mb={2}
              w={fixWidth ? { base: "250px", md: "400px", lg: "550px" } : ""}
            >
              {renderFormField(field)}
            </Td>
          );
        }
      })}
      <Td>
        {!viewOnly && (
          <Button disabled={!formEnabled} onClick={handleSubmit} type="submit">
            {btnText ? btnText : "Submit"}
          </Button>
        )}
        {showCancel && (
          <Button
            ml="5px"
            disabled={isEqual(formState, data)}
            onClick={() => setFormState({ ...data })}
          >
            {" "}
            Cancel{" "}
          </Button>
        )}
      </Td>
    </Tr>
  ) : (
    <Box component="form" role="form">
      {formData.map((field, index) => {
        const isCondRendered = isConditionRendered(field);

        //if 'other' field exists and not rendered, then delete its value in the state to prevent its dependencies to also render
        if (field.conditions && !isCondRendered) {
          delete formState[field.name];
        } else if (!field.conditions || isCondRendered) {
          return (
            <Box
              key={index}
              mb={2}
              w={fixWidth ? { base: "250px", md: "400px", lg: "550px" } : ""}
            >
              {renderFormField(field)}
            </Box>
          );
        }
      })}
      {!viewOnly && (
        <Button disabled={!formEnabled} onClick={handleSubmit} type="submit">
          {btnText ? btnText : "Submit"}
        </Button>
      )}
      {showCancel && (
        <Button
          ml="5px"
          disabled={isEqual(formState, data)}
          onClick={() => setFormState({ ...data })}
        >
          {" "}
          Cancel{" "}
        </Button>
      )}
    </Box>
  );
};

DynamicForm.propTypes = {
  formData: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      required: PropTypes.bool,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string.isRequired,
        })
      ),
    })
  ).isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  btnText: PropTypes.string,
  data: PropTypes.object,
};

export default DynamicForm;
