import React from "react";
import { useEffect, useState, useId } from "react";

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
  Font,
} from "@react-pdf/renderer";
import Logo from "../../../../assets/img/Dwelly_FullLogo_Transparent.png";
import moment from "moment";
import FontVerdana from "../../../../assets/fonts/Verdana.ttf";
import FontVerdanaBold from "../../../../assets/fonts/Verdana Bold.ttf";
import FontVerdanaItalic from "../../../../assets/fonts/Verdana Italic.ttf";
import FontVerdanaBoldItalic from "../../../../assets/fonts/Verdana Bold Italic.ttf";
import { last, upperFirst, filter } from "lodash";
import DealCalculations from "./DealCalculations";
import { useLocalStorage } from "useLocalStorage";
Font.register({
  family: "Verdana",
  fonts: [
    { src: FontVerdana },
    { src: FontVerdanaBold, fontWeight: "bold" },
    { src: FontVerdanaItalic, fontStyle: "italic" },
    { src: FontVerdanaBoldItalic, fontWeight: "bold", fontStyle: "italic" },
  ],
});
const CurrencyFormatter = (value) => {
  return new Intl.NumberFormat("en-CA", {
    style: "currency",
    currency: "CAD",
  }).format(value);
};
// Create styles
const styles = StyleSheet.create({
  page: {
    fontFamily: "Verdana",
    fontSize: 7,
    flexDirection: "column",
    columnGap: 10,
    padding: 25,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  view: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  view2: {
    flexDirection: "column",
  },
  header: {
    fontSize: 14,
    height: 20,
    fontWeight: "bold",
  },
  header2: {
    fontSize: 8,
    fontWeight: "bold",
  },
  subheader: {
    fontSize: 8,
    fontWeight: "bold",
  },
  text: {
    fontSize: 8,
    fontWeight: "normal",
  },
});

// Create Document Component
const formatCurrency = (amount) => {
  return `$${amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
};
const Invoice = ({ deal, allAgents }) => {
  const [auth, setAuth] = useLocalStorage("auth_v2", null);

  const [mlsData, setMlsData] = useState(null);
  const [transactions, setTransactions] = useState(null);
  const [RSTrust, setRSTrust] = useState([]);
  const [CTrust, setCTrust] = useState([]);
  const [General, setGeneral] = useState([]);
  const dealId = deal && deal._id;
  useEffect(() => {
    if (deal && !mlsData) {
      getMLSListingDetails(deal.MLS);
    }
  }, [deal]);
  useEffect(() => {
    fetch(`/api/deals/${dealId}/transactions`, {
      headers: {
        dwelly_token: auth.token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setTransactions(data);
      })
      .catch((e) => {
        console.log(e);
        setTransactions([]);
      });
  }, [dealId, auth]);
  useEffect(() => {
    if (dealId && transactions && transactions.length > 0) {
      setRSTrust(filter([...transactions], { account: "real_estate_trust" }));
      setCTrust(filter([...transactions], { account: "commission_trust" }));
      setGeneral(filter([...transactions], { account: "general" }));
    }
    console.log("debug_txn", { transactions });
  }, [dealId, transactions]);
  async function getMLSListingDetails(MLS) {
    let toSend;
    let resp = await (await fetch(`/api_v2/properties/${MLS}/`)).json();
    if (!resp.board_2 && !resp.board_16 && !resp.board_17) {
      console.log("WRONG MLS");
    } else {
      if (resp.board_16) {
        toSend = resp.board_16;
        console.log(resp);
      } else if (resp.board_2) {
        toSend = resp.board_2;
      } else {
        toSend = resp.board_17;
      }
    }
    if (toSend) {
      setMlsData(toSend);
    }
    console.log({ toSend });
    return toSend;
  }
  const {
    agents,
    primaryAgent,
    dwellyRebate,
    otherAgents,
    salePrice,
    buyerCommissionPercentage,
    sellerCommissionPercentage,
    totalCommissionPercentage,
    buyerCommission,
    sellerCommission,
    primaryAgentCommission,
    totalCommission,
    totalCommissionHST,
    otherAgentCommissions,
    primaryAgentEmail,
    primaryAgentInfo,
    coopRebate,
    sellerRebate,
    agentComissions,
    totalAgentCommissions,
    officeCommission,
    personaSaleFee,
    HST,
    isco_op,
    rebate,
    hasRebate,
    Comission,
    Expenses,
    BaseOfficeCommission,
    AgentsLines,
    AgentTotalsLine,
    NetOfficeLine,
  } = DealCalculations(deal, allAgents);

  const contact = {
    headings: {
      id: "Type",
      name: "Name",
      phone: "Phone",
      address: "Address",
      email: "Email",
    },
    fields: ["id", "name", "phone", "address", "email"],
    spacing: ["10%", "30%", "15%", "30%", "15%"],
  };
  const brokers = {
    headings: {
      id: "Type",
      name: "Name",
      phone: "Phone",
      address: "Address",
      agent_name: "Agent",
    },
    fields: ["id", "name", "phone", "address", "agent_name"],
    spacing: ["10%", "30%", "15%", "30%", "15%"],
  };
  const financials = {
    spacing: ["30%", "10%", "10%", "10%", "10%", "10%", "10%", "10%"],
    textAlign: [
      "left",
      "right",
      "right",
      "right",
      "right",
      "right",
      "right",
      "right",
    ],
  };
  const accountActivity = {
    spacing: ["40%", "20%", "20%", "20%"],
    textAlign: ["left", "left", "left", "right"],
  };
  const rowsRender = (person, info, empty, bold, fontSize) => {
    return (
      <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
        {info.fields.map((field, idx) => {
          const style = {
            textAlign:
              info.textAlign && info.textAlign[idx]
                ? info.textAlign[idx]
                : "left",
            width: info.spacing[idx],
            fontWeight: bold ? "bold" : "normal",
          };
          if (fontSize) style.fontSize = fontSize;
          return (
            <Text style={style}>
              {person && person[field] ? person[field] : empty ? field : ""}
            </Text>
          );
        })}
      </View>
    );
  };
  const MyDoc = () => (
    <Document>
      <Page size={"LETTER"} style={styles.page}>
        <View style={styles.view}>
          <Text style={styles.header}>Dwelly Realty Inc.</Text>
          <Text style={styles.header}>{last(deal._id.split("-"))}</Text>
        </View>
        <View style={styles.view}>
          <Text style={styles.header2}>Property: {deal.address}</Text>
          <Text style={styles.header2}>
            Status: {upperFirst(deal.status ? deal.status : "new")}
          </Text>
        </View>
        <View style={{ flexDirection: "row", marginTop: 10 }}>
          <View style={{ width: "50%" }}>
            <View style={styles.view2}>
              <View style={{ flexDirection: "row" }}>
                <Text style={{ ...styles.subheader, width: "50%" }}>
                  Type: Residential
                </Text>
                <Text style={styles.subheader}>
                  Class: {upperFirst(deal.agent_type.replace("_", "-"))}
                </Text>
              </View>
            </View>
            <View style={styles.view2}>
              <View style={{ flexDirection: "row" }}>
                <View style={{ flexDirection: "row" }}>
                  <Text style={{ ...styles.subheader, width: "40%" }}>
                    Offer Date:
                  </Text>
                  <Text style={styles.subheader}>
                    {moment(deal.acceptance_date).format("ll")}
                  </Text>
                </View>
                <View style={{ flexDirection: "row" }}>
                  <Text style={{ ...styles.subheader, width: "40%" }}>
                    Entry Date:
                  </Text>
                  <Text style={styles.subheader}>
                    {moment(deal.created_at).format("ll")}
                  </Text>
                </View>
              </View>
              <View style={{ flexDirection: "row" }}>
                <View style={{ flexDirection: "row" }}>
                  <Text style={{ ...styles.subheader, width: "40%" }}>
                    Firm Date:
                  </Text>
                  <Text style={styles.subheader}>
                    {moment(deal.expiry_date_of_all_conditions).format("ll")}
                  </Text>
                </View>
                <View style={{ flexDirection: "row" }}>
                  <Text style={{ ...styles.subheader, width: "40%" }}>
                    Close Date:
                  </Text>
                  <Text style={styles.subheader}>
                    {moment(deal.closing_date).format("ll")}
                  </Text>
                </View>
              </View>
              <View style={{ flexDirection: "row" }}>
                <View style={{ flexDirection: "row" }}>
                  <Text style={{ ...styles.subheader, width: "45%" }}>
                    Settlement Date:
                  </Text>
                  <Text style={styles.subheader}>
                    {moment(deal.closing_date).format("ll")}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View style={{ flexDirection: "column", width: "50%" }}>
            <View
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <Text style={{ textAlign: "left", width: "30%" }}>Condition</Text>
              <Text style={{ textAlign: "center", width: "20%" }}>
                Due Date
              </Text>
              <Text style={{ textAlign: "right", width: "20%" }}>Met Date</Text>
            </View>
            <View style={{ borderTop: "1" }}></View>
            {deal.condition.map((cond, i) => (
              <View
                key={"ts_cond_" + i}
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text style={{ textAlign: "left", width: "30%" }}>{cond}</Text>
                <Text style={{ textAlign: "center", width: "20%" }}>
                  {moment(deal.expiry_date_of_all_conditions).format("ll")}
                </Text>
                <Text style={{ textAlign: "right", width: "20%" }}>
                  {moment(deal.expiry_date_of_all_conditions).format("ll")}
                </Text>
              </View>
            ))}
          </View>
        </View>
        <View style={{ borderTop: "1", width: "100%", marginTop: 10 }}></View>
        <View style={styles.view}>
          <Text style={styles.header}>Contacts: </Text>
        </View>
        <View style={{ flexDirection: "column", width: "100%" }}>
          {rowsRender(contact.headings, contact, null, true)}
          <View style={{ borderTop: "1" }}></View>
          {deal.buyers &&
            deal.buyers.map((person) =>
              rowsRender({ ...person, id: "Buyer" }, contact)
            )}
          {deal.buyer_lawyers &&
            deal.buyer_lawyers[0].name &&
            deal.buyer_lawyers.map((person) =>
              rowsRender({ ...person, id: "^Lawyer" }, contact)
            )}
          {deal.sellers &&
            deal.sellers.map((person) =>
              rowsRender({ ...person, id: "Seller" }, contact)
            )}
          {deal.seller_lawyers &&
            deal.seller_lawyers[0].name &&
            deal.seller_lawyers.map((person) =>
              rowsRender({ ...person, id: "^Lawyer" }, contact)
            )}
        </View>
        <View style={styles.view}>
          <Text style={styles.header}>Outside Brokers: </Text>
        </View>
        <View style={{ flexDirection: "column", width: "100%" }}>
          {rowsRender(brokers.headings, brokers, null, true)}
          <View style={{ borderTop: "1" }}></View>
          {deal.other_brokerages &&
            deal.other_brokerages.map((person) =>
              rowsRender({ ...person, id: "Brokerage" }, brokers)
            )}
        </View>
        <View style={styles.view}>
          <Text style={styles.header}>Financial: </Text>
        </View>
        <View style={{ flexDirection: "column", width: "100%" }}>
          {rowsRender(
            null,
            {
              fields: [
                "Selling Price: " + CurrencyFormatter(deal.sale_price),
                "Listing Comm. " + sellerCommissionPercentage + "%",
                "Selling Comm. " + deal.total_commission_buyagent + "%",
                isco_op ? "A/R" : "BV",
              ],
              spacing: ["25%", "25%", "25%", "25%"],
            },
            true
          )}
          {rowsRender(
            null,
            {
              ...financials,
              fields: [
                "Income",
                "Listing",
                "< Other",
                "Co-op",
                "< Ohter",
                "Sub-Total",
                "HST",
                "Total",
              ],
            },
            true,
            true
          )}
          {
            //16125
          }
          {rowsRender(
            null,
            {
              ...financials,
              fields: [
                ...Comission.map((c, i) =>
                  i === 0 ? c : CurrencyFormatter(c)
                ),
              ],
            },
            true
          )}
          {Expenses.length > 0 &&
            rowsRender(
              null,
              {
                fields: ["Expenses"],
                spacing: ["100%"],
              },
              true,
              true
            )}
          {Expenses.map((expense, i) =>
            rowsRender(
              null,
              {
                ...financials,
                fields: [
                  ...expense.map((c, i) =>
                    i === 0 ? c : CurrencyFormatter(c)
                  ),
                ],
              },
              true
            )
          )}

          <View style={{ borderTop: "1", width: "100%", marginTop: 0 }}></View>

          {rowsRender(
            null,
            {
              ...financials,
              fields: [
                ...BaseOfficeCommission.map((c, i) =>
                  i === 0 ? c : CurrencyFormatter(c)
                ),
              ],
            },
            true
          )}
          {rowsRender(
            null,
            {
              fields: ["Agents"],
              spacing: ["100%"],
            },
            true,
            true
          )}
          {AgentsLines.map((agent, i) =>
            rowsRender(
              null,
              {
                ...financials,
                fields: [
                  ...agent.map((c, i) => (i === 0 ? c : CurrencyFormatter(c))),
                ],
              },
              true,
              false
            )
          )}

          <View style={{ borderTop: "1", width: "100%", marginTop: 0 }}></View>

          {rowsRender(
            null,
            {
              ...financials,
              fields: [
                ...AgentTotalsLine.map((c, i) =>
                  i === 0 ? c : CurrencyFormatter(c)
                ),
              ],
            },
            true,
            false
          )}
          <View style={{ borderTop: "1", width: "100%", marginTop: 0 }}></View>
          {rowsRender(
            null,
            {
              ...financials,
              fields: [
                ...NetOfficeLine.map((c, i) =>
                  i === 0 ? c : CurrencyFormatter(c)
                ),
              ],
            },
            true,
            true
          )}
          <View style={{ borderTop: "1", width: "100%", marginTop: 0 }}></View>
          <View style={{ borderTop: "1", width: "100%", marginTop: 0 }}></View>
        </View>
        <View style={styles.view}>
          <Text style={styles.header}>Agents: </Text>
        </View>
        <View style={styles.view}>
          {deal.agents.map((agent, i) => {
            const currentAgent = allAgents.find((a) => a._id === agent);

            const agentComission = agentComissions[agent];
            return (
              <View style={{ width: "25%", paddingLeft: 5, paddingRight: 5 }}>
                <Text style={{ textAlign: "center" }}>{currentAgent.name}</Text>
                <View
                  style={{ borderTop: "1", width: "100%", marginTop: 0 }}
                ></View>
                <View style={{ flexDirection: "row" }}>
                  <Text style={{ width: "50%", textAlign: "left" }}>
                    Agent Base
                  </Text>
                  <Text style={{ width: "50%", textAlign: "right" }}>
                    {CurrencyFormatter(agentComission + officeCommission)}
                  </Text>
                </View>
                <View style={{ flexDirection: "row" }}>
                  <Text style={{ width: "50%", textAlign: "left" }}>
                    Personal Deal
                  </Text>
                  <Text style={{ width: "50%", textAlign: "right" }}>
                    {CurrencyFormatter(i === 0 ? officeCommission : 0)}
                  </Text>
                </View>
                <View style={{ flexDirection: "row" }}>
                  <Text style={{ width: "50%", textAlign: "left" }}></Text>
                  <View
                    style={{ borderTop: "1", width: "50%", marginTop: 0 }}
                  ></View>
                </View>
                <View style={{ flexDirection: "row" }}>
                  <Text style={{ width: "50%", textAlign: "left" }}></Text>
                  <Text style={{ width: "50%", textAlign: "right" }}>
                    {CurrencyFormatter(agentComission)}
                  </Text>
                </View>
              </View>
            );
          })}
        </View>
        <View style={{ borderTop: "2", width: "100%", marginTop: 10 }}></View>
        <View style={styles.view}>
          <View
            style={{
              width: "50%",
              paddingLeft: 5,
              paddingRight: 5,
              borderRight: 2,
            }}
          >
            {rowsRender(
              null,
              {
                ...accountActivity,
                fields: [
                  "RealEstate Trust Activity",
                  "Date",
                  "Reference",
                  "Amount",
                ],
              },
              true,
              true,
              6
            )}
            <View
              style={{ borderTop: "1", width: "100%", marginTop: 0 }}
            ></View>
            {RSTrust.map((transaction, i) =>
              rowsRender(
                null,
                {
                  ...accountActivity,
                  fields: [
                    transaction.details,
                    transaction.date
                      ? moment(transaction.date).format("ll")
                      : "--",
                    transaction.reference,
                    CurrencyFormatter(
                      (transaction.type === "debit" ? "" : "-") +
                        transaction.amount
                    ),
                  ],
                },
                true,
                false,
                6
              )
            )}
            {rowsRender(
              null,
              {
                ...accountActivity,
                fields: [
                  "",
                  "",
                  "Balance",
                  CurrencyFormatter(
                    RSTrust.reduce(
                      (acc, t) =>
                        t.date
                          ? t.type === "debit"
                            ? acc + t.amount
                            : acc - t.amount
                          : acc,
                      0
                    )
                  ),
                ],
              },
              true,
              true,
              6
            )}
            {rowsRender(
              null,
              {
                ...accountActivity,
                fields: [
                  "Commission Trust Activity",
                  "Date",
                  "Reference",
                  "Amount",
                ],
              },
              true,
              true,
              6
            )}
            <View
              style={{ borderTop: "1", width: "100%", marginTop: 0 }}
            ></View>
            {CTrust.map((transaction, i) =>
              rowsRender(
                null,
                {
                  ...accountActivity,
                  fields: [
                    transaction.details,
                    transaction.date
                      ? moment(transaction.date).format("ll")
                      : "--",
                    transaction.reference,
                    CurrencyFormatter(
                      (transaction.type === "debit" ? "" : "-") +
                        transaction.amount
                    ),
                  ],
                },
                true,
                false,
                6
              )
            )}

            {rowsRender(
              null,
              {
                ...accountActivity,
                fields: [
                  "",
                  "",
                  "Balance",
                  CurrencyFormatter(
                    CTrust.reduce(
                      (acc, t) =>
                        t.date
                          ? t.type === "debit"
                            ? acc + t.amount
                            : acc - t.amount
                          : acc,
                      0
                    )
                  ),
                ],
              },
              true,
              true,
              6
            )}
            {rowsRender(
              null,
              {
                ...accountActivity,
                fields: ["General Activity", "Date", "Reference", "Amount"],
              },
              true,
              true,
              6
            )}
            <View
              style={{ borderTop: "1", width: "100%", marginTop: 0 }}
            ></View>
            {General.map((transaction, i) =>
              rowsRender(
                null,
                {
                  ...accountActivity,
                  fields: [
                    transaction.details,
                    transaction.date
                      ? moment(transaction.date).format("ll")
                      : "--",
                    transaction.reference,
                    CurrencyFormatter(
                      (transaction.type === "debit" ? "" : "-") +
                        transaction.amount
                    ),
                  ],
                },
                true,
                false,
                6
              )
            )}

            {rowsRender(
              null,
              {
                ...accountActivity,
                fields: [
                  "",
                  "",
                  "Balance",
                  CurrencyFormatter(
                    General.reduce(
                      (acc, t) =>
                        t.date
                          ? t.type === "debit"
                            ? acc + t.amount
                            : acc - t.amount
                          : acc,
                      0
                    )
                  ),
                ],
              },
              true,
              true,
              6
            )}
          </View>
          <View style={{ width: "50%", paddingLeft: 5, paddingRight: 5 }}>
            <Text>
              It is understood between all parties that this agreement shall
              constitute a Commission Trust Agreement as set out in the contract
            </Text>
            <View
              style={{
                borderTop: "1",
                width: "100%",
                marginTop: 5,
                marginBottom: 5,
              }}
            ></View>

            <Text>
              To the best of my knowledge the above information is correct Dated
              at Toronto on {moment().format("LL")}
            </Text>
            <View
              style={{
                borderTop: "0",
                width: "100%",
                marginTop: 5,
                marginBottom: 5,
              }}
            ></View>
            <Text>***** Office Copy *****</Text>

            {deal.agents.map((agent, i) => {
              const currentAgent = allAgents.find((a) => a._id === agent);
              return (
                <>
                  <Text>
                    {currentAgent.name +
                      (currentAgent.hst ? " HST#:" + currentAgent.hst : "")}
                  </Text>
                  <View style={styles.view}>
                    <Text style={{ width: "20%" }}></Text>
                    <Text
                      style={{
                        borderBottom: 1,
                        marginTop: 20,
                        width: "70%",
                      }}
                    >
                      Signature:
                    </Text>
                  </View>
                  <View
                    style={{ borderTop: "0", width: "100%", marginTop: 5 }}
                  ></View>
                </>
              );
            })}

            <Text>Broker of Record: DWELLY REALTY INC.</Text>
            <View style={styles.view}>
              <Text style={{ width: "20%" }}></Text>
              <Text
                style={{
                  borderBottom: 1,
                  marginTop: 20,
                  width: "70%",
                }}
              >
                Signature:
              </Text>
            </View>

            <Text style={{ textAlign: "right" }}>
              Trade #{" "}
              {moment(deal.closing_date).format("YYYYMM-") +
                last(deal._id.split("-"))}
            </Text>
          </View>
        </View>
        <View style={styles.view}>
          <Text style={styles.header}>File Requirements: </Text>
        </View>
      </Page>
    </Document>
  );
  return (
    mlsData && (
      <PDFViewer style={{ flexGrow: 1, width: "100%", height: "90vh" }}>
        <MyDoc />
      </PDFViewer>
    )
  );
};
export default Invoice;
