import React, { useState, useEffect } from "react";
import { PDFDocument } from "pdf-lib";
import JSZip from "jszip";

import {
  Button,
  Flex,
  FormControl,
  FormLabel as FormControlLabel,
  FormErrorMessage,
  Link,
  Text,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import {
  Document,
  Page,
  PDFViewer,
  BlobProvider,
  Image,
} from "@react-pdf/renderer";
import FileBase64 from "react-file-base64";
import { get, random } from "lodash";
import {
  FaFilePdf,
  FaCloudUploadAlt,
  FaFileDownload,
  FaDownload,
  FaTrash,
  FaTrashRestore,
} from "react-icons/fa";
const createBase64Zip = async (base64Files) => {
  const zip = new JSZip();

  base64Files.forEach((file, index) => {
    const { image, meta } = file;
    const { name, mime } = meta;
    const fileName = name;
    const base64Data = image.replace(/^data:([^;]+);base64,/, "");
    zip.file(fileName, base64Data, { base64: true });
  });

  const zipBlob = await zip.generateAsync({ type: "blob" });
  const zipBase64 = await blobToBase64(zipBlob);
  return zipBase64;
};

const blobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result.split(",")[1]);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};
const downloadBase64File = (file) => {
  const base64String = file.image;
  const fileName = file.meta.name;
  const contentType = file.meta.mime;
  // Remove data URL prefix if it exists
  const base64Data = base64String.replace(/^data:([^;]+);base64,/, "");

  // Convert base64 to raw binary data
  const binaryData = atob(base64Data);

  // Create array buffer from binary data
  const arrayBuffer = new ArrayBuffer(binaryData.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  // Fill array buffer with binary data
  for (let i = 0; i < binaryData.length; i++) {
    uint8Array[i] = binaryData.charCodeAt(i);
  }

  // Create blob from array buffer
  const blob = new Blob([arrayBuffer], { type: contentType });

  // Create a link element
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = fileName;

  // Append the link to the body
  document.body.appendChild(link);

  // Programmatically click the link to trigger the download
  link.click();

  // Remove the link from the document
  document.body.removeChild(link);
};
const base64ToBlobUrl = (base64String, contentType = "") => {
  // Remove data URL prefix if it exists
  const base64Data = base64String.replace(/^data:([^;]+);base64,/, "");

  // Convert base64 to raw binary data
  const binaryData = atob(base64Data);

  // Create array buffer from binary data
  const arrayBuffer = new ArrayBuffer(binaryData.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  // Fill array buffer with binary data
  for (let i = 0; i < binaryData.length; i++) {
    uint8Array[i] = binaryData.charCodeAt(i);
  }

  // Create blob from array buffer
  const blob = new Blob([uint8Array], { type: contentType });

  // Create and return blob URL
  return URL.createObjectURL(blob);
};
const joinBase64PDFs = async (base64PDFs) => {
  const mergedPdf = await PDFDocument.create();

  for (const base64 of base64PDFs) {
    try {
      // Convert base64 to Blob URL
      const blobUrl = base64ToBlobUrl(base64.image, "application/pdf");

      // Fetch the Blob data from the URL
      //const response = await fetch(blobUrl);
      //const pdfBytes = await response.arrayBuffer();
      // Load the PDF document
      const pdf = await PDFDocument.load(base64.image, {
        ignoreEncryption: true,
        throwOnInvalidObject: false,
        updateMetadata: false,
      });
      console.log({ pdf });
      // Copy pages from the loaded PDF to the merged PDF
      const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
      copiedPages.forEach((page) => mergedPdf.addPage(page));
    } catch (error) {
      console.error("Error loading PDF:", error);
    }
  }

  const mergedPdfBytes = await mergedPdf.saveAsBase64({});
  return mergedPdfBytes;
};

const getImageBlogPDF = (base64String) => {
  return (
    <Document>
      <Image src={base64String} />
    </Document>
  );
};
function randomString(length) {
  return Math.round(
    Math.pow(36, length + 1) - Math.random() * Math.pow(36, length)
  )
    .toString(36)
    .slice(1);
}
const SingleFileUploader = ({
  field,
  _id,
  name,
  auth,
  onChange,
  value,
  data,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const base64ToBlob = (base64String, contentType = "") => {
    // Remove data URL prefix if it exists
    var base64Data = base64String.replace(/^data:([^;]+);base64,/, "");

    // Convert base64 to raw binary data
    var binaryData = atob(base64Data);

    // Create array buffer from binary data
    var arrayBuffer = new ArrayBuffer(binaryData.length);
    var uint8Array = new Uint8Array(arrayBuffer);

    // Fill array buffer with binary data
    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }

    // Create blob from array buffer
    var blob = new Blob([arrayBuffer], { type: contentType });

    // Create and return blob URL
    return URL.createObjectURL(blob);
  };
  console.log("formState", { field, _id, name, value, data });
  const [files, setFiles] = useState(null);
  const [deletedFiles, setDeletedFiles] = useState({});
  const [upload, setUplaod] = useState(false);
  const [view, setView] = useState(null);
  const [viewBase64, setViewBase64] = useState(null);
  const [viewAll, setViewAll] = useState(false);
  const [allPdfs, setAllPdfs] = useState([]);
  const [key, setKey] = useState(randomString(16));
  const [cache, setCache] = useState({});
  const deleteFile = async (file, update) => {
    setDeletedFiles({ ...deletedFiles, [file.id]: true });
    if (update)
      await fetch(`/api/deals_document/${file.Key}`, {
        method: "DELETE",
        headers: {
          dwelly_token: auth.token,
        },
      });
  };
  const unDeleteFile = async (file, update) => {
    setDeletedFiles({ ...deletedFiles, [file.id]: false });
    if (update)
      await fetch(`/api/deals_document_un/${file.Key}`, {
        method: "DELETE",
        headers: {
          dwelly_token: auth.token,
        },
      });
  };
  useEffect(() => {
    console.log({ deletedFiles });
  }, [deletedFiles]);
  useEffect(() => {
    if (data) {
      let obj = {};
      data.map((file) => {
        if (file.deleted) {
          console.log("delete-", { file: file.deleted });

          obj[file.id] = true;
        } else {
          console.log("delete--", { file: file.deleted });

          obj[file.id] = false;
        }
      });
      setDeletedFiles(obj);
    }
  }, [data]);
  useEffect(() => {
    if (!isOpen) {
      setView(null);
      setViewBase64(null);
    }
  }, [isOpen]);
  const getBase64 = async (view, setViewBase64) => {
    if (cache[view.Key]) {
      setViewBase64 && setViewBase64(cache[view.Key]);
      return cache[view.Key];
    } else {
      const data = await (
        await fetch(`/api/deals_document/${view.Key}`, {
          headers: {
            dwelly_token: auth.token,
          },
        })
      ).json();
      setCache({ ...cache, [view.Key]: data });
      setViewBase64 && setViewBase64(data);
      return data;
    }
  };
  useEffect(() => {
    console.log({ view, viewBase64 });
    if (view && view.Key && !viewBase64) {
      getBase64(view, setViewBase64);
    }
  }, [view]);
  const handleFileChange = (e) => {
    if (e.target.files) {
      setFiles(e.target.files[0]);
    }
  };
  const getFiles = (files) => {
    console.log({ files });

    setFiles(files);
  };
  const handleUpload = async () => {
    // We will fill this out later
    //onFormSubmit({ [field.name]: file });
  };
  useEffect(() => {
    console.log({ files });
    if (files) {
      setUplaod(false);
      onChange({
        target: {
          name: field.name,
          value: files && files.length ? files : [],
        },
      });
    } else {
      if (data.length > 0) {
        setUplaod(false);
      } else {
        setUplaod(true);
      }
    }
  }, [files]);
  return (
    <FormControl isRequired={field.required} name={field.name}>
      <FormControlLabel>
        {field.label || _.startCase(field.name)}
      </FormControlLabel>
      {!files && !upload && data.length > 0 && (
        <>
          <Button
            colorScheme={"red"}
            onClick={() => setUplaod(true)}
            className="submit"
            size="sm"
          >
            Upload More
          </Button>
        </>
      )}
      {upload && (
        <div key={key} className="input-group">
          <FileBase64 multiple={true} onDone={getFiles} />
        </div>
      )}
      {data &&
        data.map(
          (file) =>
            !deletedFiles[file.id] && (
              <Flex>
                <FaFilePdf fontSize={"18"} color="green" />
                <Text
                  onClick={() => {
                    setView(file);
                    onOpen();
                  }}
                  fontSize={"14"}
                >
                  {file.name}
                </Text>
                <FaTrash
                  onClick={async () => deleteFile(file, true)}
                  fontSize={"18"}
                  color="green"
                />
                <FaDownload
                  onClick={async () =>
                    downloadBase64File(await getBase64(file))
                  }
                  fontSize={"18"}
                  color="green"
                />
              </Flex>
            )
        )}
      {data &&
        data.map(
          (file) =>
            deletedFiles[file.id] && (
              <Flex>
                <FaFilePdf fontSize={"18"} color="red" />
                <Text
                  onClick={() => {
                    setView(file);
                    onOpen();
                  }}
                  fontSize={"14"}
                >
                  {file.name}
                </Text>
                <FaTrashRestore
                  onClick={async () => unDeleteFile(file, true)}
                  fontSize={"18"}
                  color="red"
                />
                <FaDownload
                  onClick={async () =>
                    downloadBase64File(await getBase64(file))
                  }
                  fontSize={"18"}
                  color="red"
                />
              </Flex>
            )
        )}
      {files &&
        files.map((file) => (
          <Flex>
            <FaCloudUploadAlt fontSize={"18"} color="lightgray" />
            <Text fontSize={"14"}>{file.name}</Text>
          </Flex>
        ))}

      {files && (
        <>
          <Button
            colorScheme={"teal"}
            onClick={() => {
              setFiles(null);
              setKey(randomString(16));
            }}
            className="submit"
          >
            Cancel
          </Button>
        </>
      )}
      <Button
        colorScheme={"red"}
        onClick={async () => {
          let pdfs = [];
          let fileName = data[0].Key.split("/")[1];
          for (let file of data) {
            if (!file.deleted) pdfs.push(await getBase64(file));
            //if (!file.deleted) downloadBase64File(await getBase64(file));
          }
          createBase64Zip(pdfs)
            .then((zipBase64) => {
              downloadBase64File({
                image: zipBase64,
                meta: {
                  name: "deal_" + fileName + ".zip",
                  mime: "application/zip",
                },
              });
              console.log(zipBase64);
            })
            .catch((error) => {
              console.error("Error creating ZIP file:", error);
            });
          /*joinBase64PDFs(pdfs).then((mergedPdfBase64) => {
            downloadBase64File({
              image: mergedPdfBase64,
              meta: {
                name: "deal_" + fileName + ".pdf",
                mime: "application/pdf",
              },
            });
          });*/
        }}
        className="submit"
        size="sm"
      >
        Download All
      </Button>
      <FormErrorMessage>Please enter a correct value </FormErrorMessage>
      <Modal onClose={onClose} size={"full"} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>File: {view && view.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {view && view.Key && viewBase64 ? (
              view.mime === "application/pdf" ? (
                <object
                  data={base64ToBlob(viewBase64.image, "application/pdf")}
                  type="application/pdf"
                  style={{ height: "90vh", flexGrow: 1, width: "100%" }}
                />
              ) : (
                <img
                  src={viewBase64.image}
                  style={{ height: "90vh", flexGrow: 1, width: "100%" }}
                />
              )
            ) : (
              <Text>Loading...</Text>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </FormControl>
  );
};

export default SingleFileUploader;
