import React, { useState, useEffect, useRef } from "react";
import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Text,
  Heading,
  Button,
  Grid,
} from "@chakra-ui/react";
import { cond, filter, set, last, find, sortBy as sort } from "lodash";
import DynamicForm from "../../../../components/DynamicForm";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { useLocalStorage } from "useLocalStorage";
import DealCalculations from "./DealCalculations";
import DatePicker from "react-date-picker";
import { SingleDatepicker, RangeDatepicker } from "chakra-dayzed-datepicker";
import {
  exportComponentAsJPEG,
  exportComponentAsPDF,
  exportComponentAsPNG,
} from "react-component-export-image";

const CurrencyFormatter = (value) => {
  if (value < 0) {
    return (
      "(" +
      new Intl.NumberFormat("en-CA", {
        style: "currency",
        currency: "CAD",
        negativeFormat: "brackets",
      }).format(Math.abs(value)) +
      ")"
    );
  } else {
    return new Intl.NumberFormat("en-CA", {
      style: "currency",
      currency: "CAD",
      negativeFormat: "brackets",
    }).format(value);
  }
};
const Fields = [
  {
    type: "select",
    label: "Account",
    name: "account",
    placeholder: "Select An Account",
    required: true,
    options: [
      {
        label: "Real Estate Trust",
        value: "real_estate_trust",
      },
      {
        label: "Commission Trust",
        value: "commission_trust",
      },
      {
        label: "General",
        value: "general",
      },
    ],
  },
  { type: "text", label: "Details", name: "details" },
  {
    type: "date",
    label: "Date",
    name: "date",
    placeholder: "Select Date",
  },
  { type: "text", label: "Reference", name: "reference" },
  { type: "number", label: "Amount", name: "amount" },
  {
    type: "select",
    label: "Type",
    name: "type",
    placeholder: "Select An Type",
    required: true,
    options: [
      {
        label: "Debit",
        value: "debit",
      },
      {
        label: "Credit",
        value: "credit",
      },
    ],
  },
  {
    type: "select",
    label: "To",
    name: "to",
    placeholder: "Select...",
    conditions: [{ name: "type", value: "credit" }],
    options: [
      {
        label: "Commission Trust",
        value: "commission_trust",
      },
      {
        label: "Dwelly General",
        value: "general",
      },
      {
        label: "Buyer",
        value: "buyer",
      },
      {
        label: "Buyer Rebate",
        value: "buyer_rebate",
      },
      {
        label: "Seller",
        value: "seller",
      },
      {
        label: "Owner",
        value: "owner",
      },
      {
        label: "Seller Rebate",
        value: "seller_rebate",
      },
      {
        label: "Seller Lawyer",
        value: "seller_lawyer",
      },
      {
        label: "Dwelly Agent",
        value: "agent_dwelly",
      },
      {
        label: "Ohter Agent",
        value: "agent_other",
      },
    ],
  },
];
const DealTransactions = ({ dealId, deal, allAgents }) => {
  const [transactions, setTransactions] = useState(null);
  const [dirtyTxns, setDirtyTxns] = useState([]);
  const [RSTrust, setRSTrust] = useState([]);
  const [CTrust, setCTrust] = useState([]);
  const [General, setGeneral] = useState([]);
  const [txnId, setTxnId] = useState(null);
  const [txnData, setTxnData] = useState(null);
  const [auth, setAuth] = useLocalStorage("auth_v2", null);
  const [selectedDates, setSelectedDates] = useState([
    new Date(moment().subtract(1, "month").startOf("month").format()),
    new Date(),
  ]);
  const rstREf = useRef();
  const ctRef = useRef();
  const gRef = useRef();

  const handleFormSubmit = async (formState, auth, type) => {
    console.log("debug", { formState, dirtyTxns, transactions });

    setTransactions([
      ...transactions.map((txn) => {
        if (txn._id === formState._id) {
          return { ...formState, dirty: true };
        }
        return txn;
      }),
    ]);
    setTxnId(null);
  };
  const filterDate = (list) => {
    return list.filter((txn) => {
      return (
        new Date(txn.date) >= selectedDates[0] &&
        new Date(txn.date) <= selectedDates[1]
      );
    });
  };
  const txnVisible = (txn) => {
    return (
      new Date(txn.date) >= selectedDates[0] &&
      new Date(txn.date) <= selectedDates[1]
    );
  };
  const txn = {
    _id: "_id",
    account: "",
    amount: 0,
    details: "",
    date: undefined,
    reference: "",
    type: "debit",
    to: "commission_trust",
  };
  const titles = {
    real_estate_trust: "Real Estate Trust",
    commission_trust: "Commission Trust",
    general: "General",
  };
  useEffect(() => {
    fetch(`/api/transactions`, {
      headers: {
        dwelly_token: auth.token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setTransactions(data);
      })
      .catch((e) => {
        console.log(e);
        setTransactions([]);
      });
  }, [auth]);
  useEffect(() => {
    if (txnId) {
      if (txnId === "_id") {
        setTxnData({ ...txn, _id: uuidv4() });
      } else {
        const txn = find(transactions, { _id: txnId });
        console.log("debug", { txn });
        setTxnData(txn);
      }
    } else {
      setTxnData(null);
    }
  }, [txnId]);

  useEffect(() => {
    if (transactions && transactions.length > 0) {
      setRSTrust(
        sort(filter([...transactions], { account: "real_estate_trust" }), [
          function (o) {
            return o.date ? new Date(o.date) : new Date();
          },
        ])
      );
      setCTrust(
        sort(filter([...transactions], { account: "commission_trust" }), [
          function (o) {
            return o.date ? new Date(o.date) : new Date();
          },
        ])
      );
      setGeneral(
        sort(filter([...transactions], { account: "general" }), [
          function (o) {
            return o.date ? new Date(o.date) : new Date();
          },
        ])
      );
      setDirtyTxns(filter([...transactions], { dirty: true }));
    }
    console.log("debug_txn", { transactions });
  }, [transactions]);
  useEffect(() => {
    console.log("debug_txn", { dirtyTxns });
  }, [dirtyTxns]);
  useEffect(() => {}, [RSTrust, CTrust, General]);
  const getTable = (title, data, ref) => {
    const toText = find(Fields, { name: "to" }).options;
    return (
      <div ref={ref} style={{ width: "100%", paddingLeft: "20px" }}>
        <Heading
          as={"h2"}
          size={"sm"}
          paddingTop={"20px"}
          onClick={() => {
            exportComponentAsPNG(ref, {
              fileName: title,
              pdfOptions: {
                format: "letter",
              },
            });
          }}
        >
          {titles[title]}
        </Heading>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>Txn Date</Th>
                <Th>Details</Th>
                <Th>Reference</Th>
                <Th>To</Th>
                <Th>Amount</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data &&
                data.map((transaction) => {
                  const toLabel = find(toText, { value: transaction.to });
                  const amount = CurrencyFormatter(transaction.amount);
                  return (
                    txnVisible(transaction) && (
                      <Tr>
                        <Td>
                          {transaction.date
                            ? moment(transaction.date).format("ll")
                            : "-"}
                        </Td>
                        <Td>{transaction.details}</Td>
                        <Td>{transaction.reference}</Td>
                        <Td>{toLabel && toLabel.label}</Td>
                        <Td>
                          {transaction.type === "debit"
                            ? amount
                            : "(" + amount + ")"}
                        </Td>
                      </Tr>
                    )
                  );
                })}
              <Tr>
                <Td></Td>
                <Td></Td>
                <Td></Td>
                <Td fontWeight="bold" textAlign={"right"}>
                  Balance:
                </Td>
                <Td fontWeight="bold">
                  {data && data.length > 0
                    ? CurrencyFormatter(
                        data.reduce(
                          (acc, t) =>
                            t.date
                              ? t.type === "debit"
                                ? acc + parseFloat(t.amount)
                                : acc - parseFloat(t.amount)
                              : acc,
                          0
                        )
                      )
                    : CurrencyFormatter(0)}
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      </div>
    );
  };
  return (
    <div>
      <div style={{ marginBottom: "20px", width: "20%" }}>
        <RangeDatepicker
          selectedDates={selectedDates}
          onDateChange={setSelectedDates}
        />
      </div>
      <h2>Transactions for All Accounts</h2>

      {getTable("real_estate_trust", RSTrust, rstREf)}
      {getTable("commission_trust", CTrust, ctRef)}
      {getTable("general", General, gRef)}
    </div>
  );
};
export default DealTransactions;
