// Chakra imports
import {
  Flex,
  Grid,
  Text,
  useColorModeValue,
  Badge,
  Select,
  Button,
  Input,
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  Checkbox,
  CheckboxGroup,
  Stack,
} from "@chakra-ui/react";
import { FaTimes, FaTimesCircle } from "react-icons/fa";
// Custom components
import React, { useEffect, useState } from "react";
import { property, set, upperFirst } from "lodash";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import {
  useHistory,
  NavLink,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import DynamicForm from "../../../../components/DynamicForm";
import FormData from "./FormData";
const AddAgent = ({ onComplete, agent }) => {
  console.log({ agent });
  // Chakra color mode
  const [visible, setVisible] = useState(true);

  const [dealInfo, setAgentInfo] = useState({});
  useEffect(() => {
    if (agent) {
      setAgentInfo(agent);
    }
  }, [agent]);

  async function handleFormSubmit(formState, auth, type) {
    const fetchURL = agent ? `/api/agents/${agent._id}` : `/api/agents`;
    fetch(fetchURL, {
      method: agent ? "PUT" : "POST",
      body: JSON.stringify(formState),
      headers: { dwelly_token: auth.token },
    })
      .then(() => {
        onComplete();
      })
      .catch((e) => console.log(e));
  }

  return (
    <Card p="16px" style={{ zIndex: 1 }}>
      {visible && (
        <CardBody px="5px">
          <Grid gap="24px">
            <Flex align="left" mb="18px" direction="column">
              <DynamicForm
                formData={FormData.form}
                onFormSubmit={handleFormSubmit}
                inline={true}
                btnText={"Submit"}
                data={dealInfo}
                viewOnly={false}
              />
            </Flex>
          </Grid>
        </CardBody>
      )}
    </Card>
  );
};

export default AddAgent;
