const Calculations = (deal, allAgents) => {
  const HST = 0.13;
  const rebate = 0.01;
  let agents,
    primaryAgent,
    dwellyRebate,
    otherAgents,
    salePrice,
    buyerCommissionPercentage,
    sellerCommissionPercentage,
    totalCommissionPercentage,
    buyerCommission,
    sellerCommission,
    primaryAgentCommission,
    totalCommission,
    totalCommissionHST,
    otherAgentCommissions,
    primaryAgentEmail,
    primaryAgentInfo,
    coopRebate,
    sellerRebate,
    agentComissions,
    totalAgentCommissions,
    personaSaleFee,
    hasRebate,
    isco_op,
    officeCommission,
    lines,
    Comission,
    Expenses,
    BaseOfficeCommission,
    AgentsLines,
    AgentTotalsLine,
    NetOfficeLine;
  if (deal) {
    isco_op = deal.agent_type === "co_op";
    hasRebate = deal.dwelly_home_buyer_rebate === "yes";
    agents = [...deal.agents];
    primaryAgentEmail = agents[0];
    personaSaleFee =
      deal.personal_sale_override && parseFloat(deal.personal_sale_override) > 0
        ? parseFloat(deal.personal_sale_override)
        : 2000;
    primaryAgentInfo = allAgents.find(
      (agent) => agent._id === primaryAgentEmail
    );
    primaryAgent = agents[0].split("@")[0];

    otherAgents = agents.slice(1);

    salePrice = parseFloat(deal.sale_price);
    dwellyRebate = hasRebate
      ? parseFloat(deal.dwelly_home_buyer_rebate_yes) > 0
        ? parseFloat(deal.dwelly_home_buyer_rebate_yes)
        : salePrice * rebate
      : 0;
    buyerCommissionPercentage = deal.total_commission_buyagent
      ? parseFloat(deal.total_commission_buyagent)
      : 2.5;
    sellerCommissionPercentage =
      deal.commission_agents &&
      deal.commission_agents[primaryAgent] &&
      parseFloat(deal.commission_agents[primaryAgent]) > 0
        ? (parseFloat(deal.commission_agents[primaryAgent]) / salePrice) * 100
        : deal.total_commission_sellagent
        ? parseFloat(deal.total_commission_sellagent)
        : 2.5;
    totalCommissionPercentage =
      buyerCommissionPercentage + sellerCommissionPercentage;
    buyerCommission = (salePrice * buyerCommissionPercentage) / 100;

    sellerCommission =
      deal.commission_agents &&
      deal.commission_agents[primaryAgent] &&
      parseFloat(deal.commission_agents[primaryAgent]) > 0
        ? parseFloat(deal.commission_agents[primaryAgent])
        : (salePrice * sellerCommissionPercentage) / 100;
    if (isco_op) {
      sellerCommission = 0;
    }
    /*if (deal.agent_type !== "co_op") {
      buyerCommission = 0;
      sellerCommission =
        deal.commission_agents && deal.commission_agents[primaryAgent]
          ? parseFloat(deal.commission_agents[primaryAgent])
          : (salePrice * sellerCommissionPercentage) / 100;
    } else {
      sellerCommission = 0;
      buyerCommission =
        deal.commission_agents && deal.commission_agents[primaryAgent]
          ? parseFloat(deal.commission_agents[primaryAgent])
          : (salePrice * buyerCommissionPercentage) / 100;
    }*/
    totalCommission = buyerCommission + sellerCommission;

    if (deal.agent_type === "both") {
      primaryAgentCommission = totalCommission;
    } else if (isco_op) {
      primaryAgentCommission = hasRebate
        ? buyerCommission - dwellyRebate
        : buyerCommission;
    } else {
      primaryAgentCommission = sellerCommission;
    }
    agentComissions = {};

    otherAgentCommissions = otherAgents.reduce((acc, agent) => {
      return (
        acc +
        (deal.commission_agents && deal.commission_agents[agent.split("@")[0]]
          ? parseFloat(deal.commission_agents[agent.split("@")[0]])
          : 0)
      );
    }, 0);
    if (deal.deal_type === "Sale") {
      if (deal.sale_type === "dwelly") {
        officeCommission = 0.5 * primaryAgentCommission;
      } else {
        officeCommission =
          primaryAgentInfo && primaryAgentInfo.persona_deal
            ? parseFloat(primaryAgentInfo.persona_deal)
            : personaSaleFee;
      }
    } else {
      if (deal.lease_type === "dwelly") {
        officeCommission = 0.5 * primaryAgentCommission;
      } else {
        officeCommission = 250;
      }
    }
    primaryAgentCommission =
      primaryAgentCommission - otherAgentCommissions - officeCommission;
    deal.agents.map((agent, i) => {
      agentComissions[agent] =
        i === 0
          ? primaryAgentCommission
          : deal.commission_agents &&
            deal.commission_agents[agent.split("@")[0]]
          ? parseFloat(deal.commission_agents[agent.split("@")[0]])
          : 0;
    });
    totalCommissionHST = totalCommission * HST;
    totalAgentCommissions = Object.values(agentComissions).reduce(
      (acc, agent) => acc + agent,
      0
    );
    lines = [];
    Comission = [
      "Commission",
      sellerCommission,
      0,
      buyerCommission,
      0,
      totalCommission,
      totalCommissionHST,
      totalCommission + totalCommissionHST,
    ];
    lines.push(Comission);
    Expenses = [];
    if (!isco_op && deal.other_brokerages && deal.other_brokerages.length > 0) {
      Expenses.push([
        " " + deal.other_brokerages[0].name + " Commission",
        0,
        0,
        buyerCommission,
        0,
        buyerCommission,
        buyerCommission * HST,
        buyerCommission * (1 + HST),
      ]);
    }

    if (hasRebate && isco_op) {
      Expenses.push([
        "Rebate",
        0,
        0,
        dwellyRebate,
        0,
        dwellyRebate,
        0,
        dwellyRebate,
      ]);
    }
    if (hasRebate && !isco_op) {
      Expenses.push([
        "Rebate",
        dwellyRebate,
        0,
        0,
        0,
        dwellyRebate,
        0,
        dwellyRebate,
      ]);
    }
    lines.push(...Expenses);
    const totals = (arr) => {
      if (arr.length === 0 || arr[0].length === 0) {
        return [];
      }

      const numColumns = arr[0].length;
      const columnSums = new Array(numColumns).fill(0);

      for (let i = 0; i < arr.length; i++) {
        for (let j = 0; j < numColumns; j++) {
          columnSums[j] += arr[i][j];
        }
      }

      return columnSums;
    };

    const calculate = (orig, lines) => {
      let toSend = [];
      const total = totals(lines);
      for (let i = 1; i < orig.length; i++) {
        if (total[i]) toSend.push(orig[i] - total[i]);
        else toSend.push(orig[i]);
      }
      console.log("debug", { orig, lines, total, toSend });

      return toSend;
    };
    BaseOfficeCommission = [
      "Base Office Commission",
      ...calculate(Comission, Expenses),
    ];

    lines.push(BaseOfficeCommission);
    AgentsLines = [];

    deal.agents.map((agent, i) => {
      const currentAgent = allAgents.find((a) => a._id === agent);
      console.log("debug1", { agent, currentAgent });
      AgentsLines.push([
        "  (" +
          (i + 1) +
          ") " +
          currentAgent.name +
          (currentAgent.hst ? " HST#" + currentAgent.hst : ""),
        !isco_op ? agentComissions[agent] : 0,
        0,
        isco_op ? agentComissions[agent] : 0,
        0,
        agentComissions[agent],
        agentComissions[agent] * HST,
        agentComissions[agent] * (1 + HST),
      ]);
    });
    AgentTotalsLine = totals(AgentsLines);
    AgentTotalsLine[0] = "Agent Totals";
    NetOfficeLine = [
      "Net Office",
      ...calculate(BaseOfficeCommission, AgentsLines),
    ];
    //const totalAgentCommissions = totals(AgentsLines);
    console.log("debug2", {
      deal,
      salePrice,
      dwellyRebate,
      totalCommission,
      totalCommissionHST,
      totalCommissionPercentage,
      buyerCommission,
      buyerCommissionPercentage,
      sellerCommission,
      sellerCommissionPercentage,
      primaryAgentCommission,
      otherAgentCommissions,
      primaryAgentInfo,
      allAgents,
      officeCommission,
      coopRebate,
      sellerRebate,
      otherAgents,
      Comission,
      Expenses,
      BaseOfficeCommission,
      AgentsLines,
      AgentTotalsLine,
      NetOfficeLine,
    });
  } else {
  }

  return {
    agents,
    primaryAgent,
    dwellyRebate,
    otherAgents,
    salePrice,
    buyerCommissionPercentage,
    sellerCommissionPercentage,
    totalCommissionPercentage,
    buyerCommission,
    sellerCommission,
    primaryAgentCommission,
    totalCommission,
    totalCommissionHST,
    otherAgentCommissions,
    primaryAgentEmail,
    primaryAgentInfo,
    coopRebate,
    sellerRebate,
    agentComissions,
    totalAgentCommissions,
    officeCommission,
    personaSaleFee,
    HST,
    isco_op,
    rebate,
    hasRebate,
    Comission,
    Expenses,
    BaseOfficeCommission,
    AgentsLines,
    AgentTotalsLine,
    NetOfficeLine,
  };
};

export default Calculations;
