import { cond, multiply } from "lodash";

// // collection: form_templates
export default FormData = {
  _id: "dwelly_deal_submission",
  form: [
    { type: "upload", label: "Documents", name: "documents" },
    {
      type: "checkboxGroup",
      name: "checklist",
      superAdmin: true,
      options: [
        "RECO Guide*",
        "Deposit Letter",
        "MLS Data Info",
        "Signed Trade Sheet*",
        "Signed Rebate Agreement",
        "Schedule B*",
        "Trust Deposit Receipt",
        "Form 100 Agreement*",
        "Form 101 Agreement*",
        "Form 101 Schedule A*",
        "Form 105 Schedule+",
        "Form 123",
        "Form 161",
        "Form 170 Consent to Advertise",
        "Form 200 Listing Agreement",
        "Form 320*",
        "Form 630 (Fintrack)*",
        "Form 635 (Receipt of Funds Record)*",
        "Form 801 Offer Summary",
      ],
    },
  ],
};
