// Chakra imports
import { Flex } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Agents from "./components/Agents";
import { tablesTableData, dashboardTableData } from "variables/general";
import { useLocalStorage } from "../../../useLocalStorage";
import routes from "../../../routes";
import moment from "moment";
import { upperFirst, sortBy, orderBy } from "lodash";
import { useHistory, useParams } from "react-router-dom";
function Tables({ location }) {
  console.log({ location });
  const { id } = useParams();

  const path = location.pathname.split("/");
  const type = path[path.length - 1];
  const [auth, setAuth] = useLocalStorage("auth_v2", null);
  const [leads, setLeads] = useState(null);
  const [status, setStatus] = useState(true);
  const [allAgents, setAllAgents] = useState([]);
  const [messages, setMessages] = useState([]);
  const [deals, setDeals] = useState([]);
  const [deal, setDeal] = useState(null);
  console.log({ deal });
  const [agentFilter, setAgentFilter] = useState(null);
  const getLeads = async () => {};
  console.log({ id });

  const reLoad = async (id) => {
    if (auth) {
      if (id) {
      } else {
        console.log("get agents");
        fetch("/api/agents", { headers: { dwelly_token: auth.token } }).then(
          async (messages) => {
            setAllAgents((await messages.json()).agents);
          }
        );
      }
    }
  };
  useEffect(() => {
    reLoad(id);
  }, [id]);
  useEffect(() => {}, [auth]);

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px", sm: "50px" }}>
      {deals ? (
        <Agents
          reLoad={reLoad}
          status={status}
          setStatus={setStatus}
          title={"Agents"}
          captions={["name", "status", "_id", "phone", "site", ""]}
          data={allAgents}
        />
      ) : null}
    </Flex>
  );
}

export default Tables;
