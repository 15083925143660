import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { useLocalStorage } from "../../useLocalStorage";
import { find } from "lodash";
import {
  Input as TextField,
  FormLabel as FormControlLabel,
} from "@chakra-ui/react";
const Select2 = ({ field, value, onChange, disabled, formState }) => {
  const [auth, setAuth] = useLocalStorage("auth_v2", null);

  console.log({ field, value });
  const [dynamicData, setDynamicData] = useState(null);
  const [dynamicOptions, setDynamicOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState(
    field.multiple ? [] : null
  ); //Created to manage single or multiple selections
  useEffect(() => {
    if (field.dynamic) {
      fetch("/api" + field.dynamic, { headers: { dwelly_token: auth.token } })
        .then((res) => res.json())
        .then((data) => {
          setDynamicData(data.agents);
        });
    }
    if (value && !field.dynamic) {
      if (field.multiple) {
        let list = [];
        value.forEach((val) => {
          let option = find(field.options, { value: val });
          if (option) {
            list.push(option);
          }
        });
        setSelectedValue(list);
      } else {
        setSelectedValue(find(field.options, { value: value }));
      }
    }
  }, [field, value]);
  useEffect(() => {
    if (dynamicData) {
      console.log("dynamicData", dynamicData);
      setDynamicOptions(
        dynamicData.map((item) => ({
          value: item._id,
          label: item.name,
        }))
      );
    }
  }, [dynamicData]);
  useEffect(() => {
    if (value && field.dynamic) {
      if (field.multiple) {
        console.log("value2", value);
        let list = [];
        value.forEach((val) => {
          let option = find(dynamicOptions, { value: val });
          if (option) {
            list.push(option);
          }
        });
        setSelectedValue(list);
      } else {
        setSelectedValue(find(dynamicOptions, { value: value }));
      }
    }
  }, [dynamicOptions]);
  const handleChangeCommission = (e) => {
    console.log("value", e);
    let { name, value, checked, type } = e.target;

    onChange({
      target: {
        name,
        value,
      },
    });
  };
  const handleChange = (newValue) => {
    console.log("handleChange", { newValue });
    setSelectedValue(newValue);
    if (field.multiple) {
      onChange({
        target: {
          name: field.name,
          value: newValue.map((option) => option?.value).filter(Boolean),
        },
      });
    } else {
      onChange({
        target: {
          name: field.name,
          value: newValue?.value || "",
        },
      });
    }
  };
  const [options, setOptions] = React.useState(field.options);
  const select = (
    <Select
      disabled={disabled}
      label={field.label}
      placeholder={field.placeholder}
      value={selectedValue}
      onChange={handleChange}
      isMulti={field.multiple}
      options={field.dynamic ? dynamicOptions : field.options}
    ></Select>
  );
  if (field.name === "agents") {
    const toRender = (
      <>
        {select}
        {selectedValue.map((agent, idx) => {
          console.log({ agent });
          const key = agent.value.split("@")[0];
          const pre = "commission_" + field.name;
          const name = pre + "." + key;
          const value =
            formState[pre] && formState[pre][key] ? formState[pre][key] : "";

          console.log({
            old: formState[name],
            new: formState[pre] ? formState[pre][key] : "",
          });
          return (
            <>
              <>{agent.label} Comission</>
              <TextField
                readOnly={field.viewOnly}
                placeholder={"Comission in $"}
                name={name}
                width={"100%"}
                value={value}
                type="number"
                onChange={handleChangeCommission}
              />
            </>
          );
        })}
      </>
    );
    return options && toRender;
  }
  return options && select;
};

Select2.propTypes = {
  field: PropTypes.shape({
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
    multiple: PropTypes.bool,
    dynamic: PropTypes.shape({
      url: PropTypes.string,
      value: PropTypes.string,
      label: PropTypes.string,
    }),
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default Select2;
