import { cond, multiply } from "lodash";

// // collection: form_templates
export default FormData = {
  _id: "dwelly_deal_submission",
  form: [
    { type: "text", label: "Deal ID", name: "deal_id", required: false },
    {
      type: "select",
      label: "Status",
      name: "status",
      placeholder: "Select An Option",
      options: [
        {
          label: "Active",
          value: "active",
        },
        {
          label: "Closed",
          value: "closed",
        },
        {
          label: "Cancelled",
          value: "cancelled",
        },
        {
          label: "Deleted",
          value: "deleted",
        },
      ],
    },
    {
      type: "select",
      label: "Agents Involved",
      name: "agents",
      placeholder: "Select An Agent",
      multiple: true,
      required: true,
      dynamic: "/agents",
      options: [
        {
          label: "The Listing Agent",
          value: "listing agent",
        },
        {
          label: "The Co-op Agent",
          value: "co-op agent",
        },
        {
          label: "Both",
          value: "both",
        },
      ],
    },

    {
      type: "number",
      label: "Sale Price ($)",
      name: "sale_price",
      required: true,
    },
    {
      type: "text",
      label: "Buyer Deposit Name",
      name: "buyer_deposit_name",
    },
    {
      type: "number",
      label: "Buyer Deposit ($)",
      name: "buyer_deposit",
    },
    {
      type: "date",
      label: "Buyer Deposit Date",
      name: "buyer_deposit_date",
    },

    {
      type: "group",
      label: "Seller",
      placeholder: "Seller",
      name: "sellers",
      fields: [
        { type: "text", label: "Name", name: "name" },
        { type: "tel", label: "Phone", name: "phone" },
        { type: "email", label: "Email", name: "email" },
        { type: "text", label: "Address", name: "address" },
      ],
    },
    {
      type: "group",
      label: "Buyer",
      placeholder: "Buyer",
      name: "buyers",
      fields: [
        { type: "text", label: "Name", name: "name" },
        { type: "tel", label: "Phone", name: "phone" },
        { type: "email", label: "Email", name: "email" },
        { type: "text", label: "Address", name: "address" },
      ],
    },
    {
      type: "group",
      label: "Other Brokerage",
      placeholder: "Other Brokerage",
      name: "other_brokerages",
      fields: [
        { type: "text", label: "Name", name: "name" },
        { type: "text", label: "Agent Name", name: "agent_name" },
        { type: "tel", label: "Phone", name: "phone" },
        { type: "email", label: "Email", name: "email" },
        { type: "text", label: "Address", name: "address" },
      ],
    },

    {
      type: "checkboxGroup",
      name: "condition",
      required: true,
      options: [
        "Status Certificate",
        "Inspection",
        "Financing",
        "Lawyer Review",
        "None",
        "Other",
      ],
    },
    {
      type: "text",
      label: "Other",
      name: "condition_other",
      conditions: [{ name: "condition", value: "Other" }],
      required: true,
    },
    {
      type: "date",
      name: "offer_date",
      required: true,
    },
    {
      type: "date",
      name: "acceptance_date",
      required: true,
    },
    {
      type: "date",
      name: "closing_date",
      required: true,
    },
    {
      type: "date",
      name: "expiry_date_of_all_conditions",
      required: true,
    },
    {
      type: "select",
      label: "Primary Agent is",
      name: "agent_type",
      placeholder: "Select An Option",
      required: true,
      options: [
        {
          label: "The Listing Agent",
          value: "listing",
        },
        {
          label: "The Co-op Agent",
          value: "co_op",
        },
        {
          label: "Both",
          value: "both",
        },
      ],
    },
    {
      type: "select",
      name: "sale_type",
      label: "Sale Type",
      placeholder: "Select An Option",
      required: true,
      options: [
        {
          label: "Dwelly Sale",
          value: "dwelly",
        },
        {
          label: "Personal Sale",
          value: "personal",
        },
      ],
      conditions: [{ name: "deal_type", value: "Sale" }],
    },
    {
      type: "select",
      name: "lease_type",
      label: "Lease Type",
      placeholder: "Select An Option",
      required: true,
      options: [
        {
          label: "Dwelly Lease",
          value: "dwelly lease",
        },
        {
          label: "Personal lease",
          value: "personal lease",
        },
      ],
      conditions: [{ name: "deal_type", value: "Lease" }],
    },
    {
      type: "text",
      name: "personal_sale_override",
      label: "Override Dwelly Commission Amount",
      conditions: [{ name: "sale_type", value: "personal" }],
      superAdmin: true,
    },
    {
      type: "select",
      label: "Total Commission Lease",
      name: "total_commission_lease",
      placeholder: "Select An Option",
      required: true,
      options: [
        {
          label: "1 Month's Rent",
          value: "1 month's rent",
        },
        {
          label: "1/2 Month's Rent",
          value: "1/2 month's rent",
        },
        {
          label: "Other",
          value: "Other",
        },
      ],
      conditions: [
        {
          name: "lease_type",
          value: "personal lease",
        },
      ],
    },
    {
      type: "text",
      name: "total_commission_lease_other",
      conditions: [{ name: "total_commission_lease", value: "Other" }],
      required: true,
    },
    {
      type: "number",
      name: "total_commission_sellagent",
      label: "Seller's Agent Commission",
      placeholder: "Amount in %",
      default: 2.5,
    },

    {
      type: "number",
      name: "total_commission_buyagent",
      label: "Buyers's Agent Commission",
      placeholder: "Amount in %",
      default: 2.5,
    },
    {
      type: "select",
      label: "Does the Dwelly Home Buyer Rebate Apply",
      placeholder: "Select An Option",
      name: "dwelly_home_buyer_rebate",
      required: true,
      options: [
        {
          label: "Yes",
          value: "yes",
        },
        {
          label: "No",
          value: "no",
        },
        {
          label: "Other",
          value: "Other",
        },
      ],
    },
    {
      type: "text",
      name: "dwelly_home_buyer_rebate_yes",
      label: "Override Dwelly Rebate Amount",
      conditions: [{ name: "dwelly_home_buyer_rebate", value: "yes" }],
    },
    {
      type: "text",
      name: "dwelly_home_buyer_rebate_other",
      conditions: [{ name: "dwelly_home_buyer_rebate", value: "Other" }],
      required: true,
    },
    {
      type: "group",
      label: "Seller Lawyer",
      placeholder: "Seller Lawyer",
      name: "seller_lawyers",
      fields: [
        { type: "text", label: "Name", name: "name" },
        { type: "text", label: "Company Name", name: "company_name" },
        { type: "tel", label: "Phone", name: "phone" },
        { type: "email", label: "Email", name: "email" },
        { type: "text", label: "Address", name: "address" },
      ],
    },
    {
      type: "group",
      label: "Buyer Lawyer",
      placeholder: "Buyer Lawyer",
      name: "buyer_lawyers",
      fields: [
        { type: "text", label: "Name", name: "name" },
        { type: "text", label: "Company Name", name: "company_name" },
        { type: "tel", label: "Phone", name: "phone" },
        { type: "email", label: "Email", name: "email" },
        { type: "text", label: "Address", name: "address" },
      ],
    },
  ],
};
