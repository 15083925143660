// Chakra imports
import {
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Switch,
  Select,
  Grid,
  GridItem,
  Button,
  Spacer,
  Tooltip,
  Badge,
} from "@chakra-ui/react";
import moment from "moment";

// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import React, { useEffect, useState, useId } from "react";
import AddAgent from "./AddAgent";
import { useHistory } from "react-router-dom";
import { upperCase, upperFirst, last } from "lodash";
import {
  Page,
  Text as PDFText,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  PDFViewer,
  Font,
} from "@react-pdf/renderer";
import { Avatar, Flex, Td } from "@chakra-ui/react";
import { NavLink, useLocation } from "react-router-dom";
import { useLocalStorage } from "../../../../useLocalStorage";
function TablesTableRow(props) {
  const [auth, setAuth] = useLocalStorage("auth_v2", null);
  const isSuperAdmin = auth?.roles?.superAdmin;
  const { row, captions, select } = props;
  console.log({ captions, row });
  const textColor = useColorModeValue("gray.700", "white");
  const bgStatus = useColorModeValue("gray.400", "#1a202c");
  const colorStatus = useColorModeValue("white", "gray.400");
  const nav = (
    <Button
      p="0px"
      bg="transparent"
      variant="no-hover"
      onClick={() => select(row)}
    >
      <Text fontSize="md" color="gray.400" fontWeight="bold" cursor="pointer">
        Edit Agent
      </Text>
    </Button>
  );
  return (
    <Tr>
      {captions.map((caption, idx) => {
        if (caption !== "")
          return (
            <Td key={"d_" + idx} pl="0px">
              <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                <Flex direction="column">
                  <Text
                    fontSize="md"
                    color={textColor}
                    fontWeight="bold"
                    minWidth="100%"
                    textDecoration={"none"}
                  >
                    {row && row[caption] && typeof row[caption] === "string"
                      ? row[caption]
                      : Object.values(row[caption]).map((item) => (
                          <Badge
                            style={{ textTransform: "lowercase" }}
                            ml="1"
                            fontSize="0.8em"
                            colorScheme="green"
                          >
                            {item}
                          </Badge>
                        ))}
                  </Text>
                </Flex>
              </Flex>
            </Td>
          );
      })}

      <Td display={{ sm: "none", md: "table-cell" }}>{nav}</Td>
    </Tr>
  );
}

const CurrencyFormatter = (value) => {
  return new Intl.NumberFormat("en-CA", {
    style: "currency",
    currency: "CAD",
  }).format(value);
};
const Authors = ({
  title,
  captions,
  data,
  status,
  setStatus,
  allAgents,
  selectAgent,
  reLoad,
  dealId,
  deal,
  reSet,
}) => {
  //const [status, setStatus] = useState(true)
  const [agent, setAgent] = useState(null);
  const [editing, setEditing] = useState(false);
  const history = useHistory();
  useEffect(() => {
    if (dealId) {
      setEditing(true);
    }
  }, [dealId]);
  useEffect(() => {
    if (agent) {
      console.log({ agent });
      setEditing(true);
    } else {
      setEditing(false);
    }
  }, [agent]);
  console.log({ dealId, editing, captions });
  const textColor = useColorModeValue("gray.700", "white");
  const onComplete = () => {
    setEditing(false);
    reLoad();
  };

  const table = (
    <Table variant="simple" color={textColor}>
      <Thead>
        <Tr my=".8rem" pl="0px" color="gray.400">
          {captions.map((caption, idx) => {
            return (
              <Th
                display={{
                  sm: idx === 0 ? "table-cell" : "none",
                  md: "table-cell",
                }}
                color="gray.400"
                key={"c_" + idx}
                ps={idx === 0 ? "0px" : null}
              >
                {upperCase(caption)}
              </Th>
            );
          })}
        </Tr>
      </Thead>
      <Tbody>
        {data.map((row, i) => {
          return (
            <TablesTableRow
              select={setAgent}
              title={title}
              captions={captions}
              agentInfo={captions[2] === "Agent"}
              key={`tr-${i}`}
              name={row.name}
              logo={row.logo}
              email={row.email}
              subdomain={row.subdomain}
              domain={row.domain}
              status={row.status}
              date={row.date}
              row={row}
            />
          );
        })}
      </Tbody>
    </Table>
  );

  const old = (
    <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
      <CardHeader p="6px 0px 22px 0px">
        <Text fontSize="xl" color={textColor} fontWeight="bold">
          {editing ? (dealId ? "Update " : "Add ") : ""}
          {title}
        </Text>
        <Spacer />
        {editing ? (
          <Button
            onClick={() => {
              setEditing(false);
              setAgent(null);
            }}
            colorScheme="red"
            marginLeft={"10px"}
            alignSelf={"right"}
          >
            Cancel
          </Button>
        ) : (
          <Button
            onClick={() => {
              setEditing(true);
            }}
            colorScheme="teal"
            marginLeft={"10px"}
            alignSelf={"right"}
          >
            Add Agent
          </Button>
        )}
      </CardHeader>
      <CardBody>
        <Grid width={"100%"}>
          <GridItem>
            {editing ? (
              <AddAgent onComplete={onComplete} agent={agent} />
            ) : (
              table
            )}
          </GridItem>
        </Grid>
      </CardBody>
    </Card>
  );

  return old;
};
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    columnGap: 10,
    padding: 25,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  view: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  view2: {
    flexDirection: "column",
  },
  header: {
    fontSize: 14,
    height: 20,
    fontWeight: "bold",
  },
  header2: {
    fontSize: 12,
    fontWeight: "bold",
  },
  subheader: {
    fontSize: 8,
    fontWeight: "bold",
  },
  text: {
    fontSize: 8,
    fontWeight: "normal",
  },
});

export default Authors;
